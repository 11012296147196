import { GenieMan, ImageStage, WhiteCheck } from "assets";
import { Prompt } from "interfaces";
import React, { Fragment, useEffect, useState } from "react";
import classes from "styles/components/Genie.module.scss";
import Button from "./Button";

interface Props {
  prompts: Prompt[];
  actionText?: string;
  action?: () => void;
  reset?: any;
  initialDelay?: number;
  checked?: boolean;
}

export default function Genie({
  prompts,
  actionText,
  action,
  reset,
  initialDelay = 300,
  checked,
}: Props) {
  const [promptVisible, setPromptVisible] = useState([]);
  const showPrompt = (curr: number = 0) => {
    if (curr <= prompts.length) {
      setPromptVisible((p) => [...p, true]);
      setTimeout(() => showPrompt(curr + 1), 300);
    }
  };
  useEffect(() => {
    setPromptVisible([]);
    setTimeout(showPrompt, initialDelay);
  }, [reset]);
  return (
    <div className={classes.container}>
      {/* <div className={classes.illustration}>
        <div className={classes.genie}>
          <GenieMan />
        </div>
        <div className={classes.stage}>
          <ImageStage />
        </div>
      </div> */}
      <div className={classes.prompts}>
        {prompts.map(
          ({ title: Title, description, servicePrompt, components }, idx) => (
            <div className={classes.promptWrapper} key={idx}>
              <div className={classes.illustration}>
                {idx === 0 && (
                  <div className={classes.absolute}>
                    <div className={classes.genie}>
                      <GenieMan />
                    </div>
                    <div className={classes.stage}>
                      <ImageStage />
                    </div>
                  </div>
                )}
              </div>
              <div
                className={[
                  classes.prompt,
                  promptVisible[idx] ? classes.visible : classes.hidden,
                ].join(" ")}

                // style={{
                //   animationDelay: `${idx * 0.3}s`,
                // }}
              >
                {Title && <p className={classes.title}>{Title}</p>}
                {description && (
                  <p className={classes.description}>
                    {description.split("\n").map((line) => (
                      <Fragment key={line}>
                        {line} <br />
                      </Fragment>
                    ))}
                  </p>
                )}
                {servicePrompt && (
                  <p className={classes.servicePrompt}>{servicePrompt}</p>
                )}
                {components && (
                  <div className={classes.components}>
                    {components.map((Component, idx) => (
                      <Component key={idx} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )
        )}
        {actionText && (
          <div className={classes.promptWrapper}>
            <div className={classes.illustration} />
            <Button
              variant="outlined"
              className={[
                classes.action,
                promptVisible[prompts.length]
                  ? classes.visible
                  : classes.hidden,
              ].join(" ")}
              onClick={action}
              flex={typeof checked === "boolean"}
            >
              <p>{actionText}</p>
              {typeof checked === "boolean" && (
                // <input checked={checked} type="checkbox" />
                <div
                  className={[
                    classes.checkbox,
                    checked ? classes.checked : classes.unchecked,
                  ].join(" ")}
                >
                  {checked && <WhiteCheck />}
                </div>
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
