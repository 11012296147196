import Button from "components/Button";
import Form from "components/Form";
import Section from "components/Section";
import OrderDetailsCard from "components/Workflow/OrderDetailsCard";
import { createUser } from "config/firebase";
import { useUser } from "contexts/user";
import useForm from "hooks/useForm";
import { FormProp, HandleSubmit } from "interfaces";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useWorkflow, WorkflowContext } from "pages/workflow";
import { useEffect, useMemo, useState } from "react";
import classes from "styles/pages/Success.module.scss";
import { event } from "utils/gtag";
import { object, string } from "yup";
import Meta from "components/Meta";
import { fetchOrder } from "services/workflow";
import Loader from "components/Loader";
import { fetchUserWithOrderExists } from "services/users";
import { spreadArray } from "utils/spreadIf";

interface Props {}

const Success: NextPage<Props> = () => {
  const { push } = useRouter();
  const { user, sessionsChange } = useUser();
  const [successLoading, setSuccessLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const {
    setValues,
    setOrderId,
    values,
    setMaxPage,
    setSkipped,
    redirectStep,
    setRedirectStep,
    setSubmitted,
    successOrder,
    setSuccessOrder,
  } = useWorkflow();

  const {
    query: { order_id },
  } = useRouter();

  useEffect(() => {
    if (sessionsChange) {
      setSuccessLoading(true);
      fetchOrder({ order_id })
        .then((res) => setSuccessOrder(res.body || null))
        .catch()
        .finally(() => setSuccessLoading(false));
    }
  }, [order_id, sessionsChange]);

  useEffect(() => {
    fetchUserWithOrderExists({ order_id }).then((res) =>
      setEmailExists(res.body?.status_present)
    );
  }, [order_id]);

  const entries: FormProp[] = useMemo(
    () => [
      [
        {
          name: "password",
          label: "Password",
          placeholder:
            "Enter Password " +
            (successOrder?.user?.email ? `for ${successOrder.user.email}` : ""),
          type: "password",
        },
        ...spreadArray(
          {
            name: "passwordConfirmation",
            label: "Retype Password",
            placeholder: "Enter Password",
            type: "password",
          },
          emailExists
        ),
      ],
    ],
    [emailExists]
  );

  const schema = useMemo(
    () =>
      object().shape({
        password: string()
          .required("Password is required")
          .min(6)
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
          ),
        passwordConfirmation: emailExists
          ? string().test("passwords-match", "Passwords must match", function(
              value
            ) {
              return this.parent.password === value;
            })
          : undefined,
      }),
    [emailExists]
  );

  useEffect(() => {
    typeof window !== "undefined" && localStorage.removeItem("business_name");
    event({ action: "purchase" });
    setSubmitted(true);
    setValues({});
    setOrderId(undefined);
    setMaxPage(0);
    setSkipped([]);
    typeof window !== "undefined" && localStorage.removeItem("business_name");
  }, []);

  const handleSubmit: HandleSubmit = async () => {
    event({ action: "workflow_step_5_submit" });
    const { password } = values;
    const user = await createUser({
      email: successOrder?.user.email,
      password,
    });
    if (user) push("/");
  };

  const { formProps } = useForm({
    redirectStep,
    setRedirectStep,
    entries,
    schema,
    onSubmit: handleSubmit,
    values,
  });
  return (
    <>
      <Meta tags={{ title: "Order Submitted" }} />
      <Section
        className={classes.container}
        innerClassName={classes.innerContainer}
      >
        <div className={classes.main}>
          {successLoading ? (
            <Loader messages={["Loading your order"]} />
          ) : (
            <>
              <div className={classes.text}>
                <h1>{successOrder ? "Congratulations!" : "Order Not found"}</h1>
                <p>
                  {successOrder ? (
                    `You've taken a big step in starting your business. Focus on what
              you do best and we'll handle the boring business filing paperwork.
              Check your email for your order confirmation and details about
              your filing process.`
                  ) : sessionsChange ? (
                    <>
                      Order with ID: <b>{order_id}</b> was not found for the
                      current user
                    </>
                  ) : (
                    "Please login and try again"
                  )}
                </p>
                {successOrder &&
                  (!user?.email ||
                    user?.email !== successOrder?.user?.email) && (
                    <>
                      <h2>Create your IncDecentral account</h2>
                      <p>
                        Enter a password for {successOrder?.user?.email} to
                        complete your FREE IncDecentral account.
                      </p>
                    </>
                  )}
              </div>
              {successOrder
                ? (!user?.email ||
                    user?.email !== successOrder?.user?.email) && (
                    <Form
                      className={classes.form}
                      {...formProps}
                      context={WorkflowContext}
                    >
                      <Button theme="secondary">
                        {emailExists ? "Login" : "Create Account"}
                      </Button>
                    </Form>
                  )
                : null}
              {successOrder &&
                user?.email &&
                user?.email === successOrder?.user?.email && (
                  <Button link="/dashboard" className={classes.dashLink}>
                    Go to Dashboard
                  </Button>
                )}
            </>
          )}
        </div>
        <OrderDetailsCard />
      </Section>
    </>
  );
};

export default Success;
