import { DropArrowSilver, FeatureBullet } from "assets";
import { Service } from "interfaces";
import React, { useState } from "react";
import classes from "styles/components/ServiceCard.module.scss";
import Button from "./Button";

interface Props extends Service {
  isAdded?: boolean;
  setIsAdded?: (p: boolean) => void;
  collapsible?: boolean;
}

export default function ServiceCard({
  features,
  image: Image,
  price,
  title,
  unit,
  isAdded,
  setIsAdded,
  collapsible,
}: Props) {
  const [showFeatures, setShowFeatures] = useState(!collapsible);
  return (
    <div
      className={[
        classes.container,
        showFeatures ? classes.expanded : classes.collapsed,
      ].join(" ")}
    >
      <div className={classes.main}>
        <div className={classes.image}>{<Image />}</div>
        <div className={classes.text}>
          <h3>{title}</h3>
          <div className={classes.price}>
            <p>
              <span>${price}</span>
              <span>/{unit}</span>
            </p>
            <Button
              variant={isAdded ? "filled" : "outlined"}
              theme="secondary"
              size="s"
              onClick={() => setIsAdded?.(!isAdded)}
            >
              {isAdded ? "Remove" : "Add"}
            </Button>
          </div>
        </div>
      </div>
      {!!features?.length && (
        <>
          <hr />
          {collapsible && (
            <>
              <div
                onClick={() => setShowFeatures((p) => !p)}
                className={classes.toggle}
              >
                <DropArrowSilver />
                <p>{showFeatures ? "less" : "more"} details</p>
              </div>
              <hr className={showFeatures ? "" : classes.fade} />
            </>
          )}
          <div className={classes.features}>
            {features.map((feature, idx) => (
              <div key={feature + idx} className={classes.feature}>
                <div className={classes.bullet}>
                  <FeatureBullet />
                </div>
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
