import Form from "components/Form";
import WorkflowButtons from "components/FormInput/WorkflowButtons";
import Genie from "components/Genie";
import { FormProp, HandleSubmit, Prompt } from "interfaces";
import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import classes from "styles/components/FormInput.module.scss";
import useForm from "hooks/useForm";
import { useWorkflow, WorkflowContext } from "pages/workflow";
import { postWorkflow2 } from "services/workflow";
import { useRouter } from "next/router";
import { event } from "utils/gtag";
import Meta from "components/Meta";

const schema = [
  object().shape({
    business_name: string()
      .required()
      .max(100),
    designator: string()
      .required()
      .typeError("Designator is required"),
  }),
  object().shape({
    category: string()
      .required("Category is required")
      .typeError("Category is required"),
    description: string()
      .required("Description is required")
      .max(200, "Description must 200 characters or less")
      .typeError("Description is required"),
  }),
];

const designators = {
  LLC: ["LLC", "LIMITED LIABILITY COMPANY", "L.L.C."],
  S: ["INCORPORATED", "CORP.", "CORPORATION", "CORP", "INC", "INC."],
  C: ["INCORPORATED", "CORP.", "CORPORATION", "CORP", "INC", "INC."],
  NP: ["INC", "LIMITED", "CORPORATION", "INCORPORATED", "CORP."],
  DAO: ["DAO", "LAO", "Distributed Atonomous Organization"],
};

export default function BusinessDetails() {
  try {
    const [current, setCurrent] = useState(0);
    const { values, setValues, redirectStep } = useWorkflow();
    const { push, pathname } = useRouter();

    useEffect(() => {
      event({ action: "workflow_step_2_view" });
    }, []);

    const pages: {
      prompts: Prompt[];
      entries: FormProp[];
    }[] = [
      {
        prompts: [
          {
            title: `Welcome ${values?.firstName}!`,
            description:
              "You are embarking on an exciting journey and I'm glad to be here to help! Now I need to know what you wish to name your business.",
          },
        ],
        entries: [
          [
            {
              name: "business_name",
              label: "Business Name",
              placeholder: "IncDecentral",
            },
            {
              name: "designator",
              label: "Designator",
              placeholder: "Select a Designator",
              type: "select",
              options: designators[values?.entity]?.map((d) => ({ value: d })),
            },
          ],
          [
            () =>
              values?.business_name ? (
                <div className={classes.businessName}>
                  {values?.business_name} {values?.designator}
                </div>
              ) : null,
            () => <div />,
          ],
        ],
      },
      {
        prompts: [
          {
            title: `${values?.business_name} ${values?.designator} sounds like a great name!`,
            description:
              "We like to help ambitious people like you grow. The Secretary of State will want to know what type of business you will be doing.",
          },
        ],
        entries: [
          [
            {
              name: "category",
              label: "What category does your business fall under?",
              type: "select",
              placeholder: "Select a category",
              searchable: true,
              options: [
                { value: "Accountant / Tax Services" },
                { value: "Agriculture" },
                { value: "Attorney / Law firm" },
                { value: "Automotive" },
                { value: "Bar Restaurant" },
                { value: "Construction" },
                { value: "Consultant" },
                { value: "Dentist" },
                { value: "Doctors and other health professionals" },
                { value: "E-commerce (online retail)" },
                { value: "Entertainment" },
                { value: "Financial services" },
                { value: "Food and Beverage" },
                { value: "General Contractor" },
                { value: "Insurance" },
                { value: "Internet / Web based services" },
                { value: "Private Equity & Investment Firms" },
                { value: "Real Estate" },
                { value: "Retail (physical store front)" },
                { value: "Transportation" },
                { value: "Trucking" },
                { value: "Other" },
              ],
            },
          ],
          {
            name: "description",
            type: "textarea",
            placeholder:
              "My product helps people incorporate businesses fast. We help you manage your business and keep track of things too.",
            label: "Can you describe your business for us? (max. 200 words)",
          },
        ],
      },
    ];

    useEffect(() => {
      if (!designators[values?.entity]?.includes(values?.designator))
        setValues((p) => ({ ...p, designator: "" }));
    }, [values?.designator]);

    useEffect(() => {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }, [current]);

    const handleSubmit: HandleSubmit = async (redirect) => {
      event({ action: "workflow_step_2_submit" });
      switch (current) {
        case 0:
          if (typeof redirect === "number") {
            await postWorkflow2({
              current_step: 2,
              business_name: values?.business_name,
              designator: values?.designator,
              category: values?.category,
              description: values?.description,
            });
            push({ pathname, query: { step: redirect } });
          } else setCurrent(1);
          resetSubmitted();
          break;
        case 1:
          await postWorkflow2({
            current_step: 2,
            business_name: values?.business_name,
            designator: values?.designator,
            category: values?.category,
            description: values?.description,
          });
          push({
            pathname,
            query: { step: typeof redirect === "number" ? redirect : 3 },
          });
          break;
      }
    };

    const { formProps, resetSubmitted } = useForm({
      redirectStep,
      schema: schema[current],
      entries: pages[current].entries,
      onSubmit: handleSubmit,
      values,
    });
    return (
      <>
        <Meta tags={{ title: "Business Details" }} />
        <div>
          <Genie reset={current} prompts={pages?.[current]?.prompts} />
          <Form context={WorkflowContext} {...formProps}>
            <WorkflowButtons
              prev={() => {
                if (current > 0) setCurrent(current - 1);
                else return 1;
              }}
            />
          </Form>
        </div>
      </>
    );
  } catch (error) {
    return <></>;
  }
}
