import Breadcrumbs from "components/Workflow/Breadcrumbs";
import OrderDetailsCard from "components/Workflow/OrderDetailsCard";
import Section from "components/Section";
import { NextPage } from "next";
import { useRouter } from "next/router";
import classes from "styles/pages/Workflow.module.scss";
import {
  AddOn,
  anyObject,
  Cart,
  Order,
  Product,
  ShopperAggregate,
  State,
  Step,
} from "interfaces";

import YourInformation from "components/Workflow/YourInformation";
import BusinessDetails from "components/Workflow/BusinessDetails";
import BusinessAddress from "components/Workflow/BusinessAddress";
import Services from "components/Workflow/Services";
import ReviewPay from "components/Workflow/ReviewPay";
import {
  createContext,
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import SelectPlan from "components/Workflow/SelectPlan";
import { fetchAddons, fetchProducts, fetchStates } from "services/constants";
import useLocalStorage from "hooks/useLocalStorage";
import {
  fetchCart,
  fetchHistory,
  fetchOrder,
  fetchPendingAddons,
  postCoupon,
  removeCoupon,
} from "services/workflow";
import Success from "components/Workflow/Success";
import { useUser } from "contexts/user";
import { fetchPaymentStatus } from "services/orders";
import { ModalProvider } from "contexts/modal";
import { getLocal } from "utils/getLocal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchAggregates } from "services/shopper";
import { getInt } from "utils/format";
import { toast } from "react-toastify";
import { getBool } from "utils/parse";

export const allowedEntities = ["C", "S", "NP", "LLC"];

const steps: Step[] = [
  { label: "Select Plan", Component: SelectPlan },
  { label: "Your Information", Component: YourInformation },
  { label: "Business Details", Component: BusinessDetails },
  { label: "Business Address", Component: BusinessAddress },
  { label: "Services for You", Component: Services },
  { label: "Review & Pay", Component: ReviewPay },
];

interface Props {
  success: boolean;
  step: number;
  aggregate?: ShopperAggregate;
  state: string;
  entity: string;
  plan: number;
  business: string;
  coupon: string;
  new_order: boolean;
  latest: boolean;
}

interface ContextProps extends Props {
  states?: State[];
  products?: Product[];
  values: anyObject;
  setValues: Dispatch<SetStateAction<anyObject>>;
  orderId?: string;
  setOrderId: Dispatch<SetStateAction<string>>;
  changeValue: (key: string) => (value: any) => void;
  order?: Order;
  successOrder?: Order;
  setSuccessOrder: Dispatch<SetStateAction<Order>>;
  servicesDetails?: anyObject[];
  total: number;
  maxPage: number;
  getOrder: () => Promise<void>;
  getHistory: () => Promise<void>;
  getCart: (props?: anyObject) => Promise<void>;
  cart?: Cart;
  setMaxPage: Dispatch<SetStateAction<number>>;
  pending: AddOn[];
  getPendingAddons: () => Promise<void>;
  skipped: string[];
  setSkipped: Dispatch<SetStateAction<string[]>>;
  aggregate?: ShopperAggregate;
  redirectStep: number;
  setRedirectStep: Dispatch<SetStateAction<number>>;
  clearLocal: () => void;
  submitted: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

const planIndex = {
  1: "New Business",
  2: "Ready to Open",
  3: "Ready to Grow",
};

export const WorkflowContext = createContext<ContextProps>({
  values: {},
  setValues: () => {},
  setOrderId: () => {},
  changeValue: () => () => {},
  total: 0,
  maxPage: 0,
  getOrder: async () => {},
  getHistory: async () => {},
  getCart: async () => {},
  setMaxPage: () => {},
  pending: [],
  getPendingAddons: async () => {},
  skipped: [],
  setSkipped: () => {},
  redirectStep: NaN,
  setRedirectStep: () => {},
  entity: "",
  plan: 0,
  state: "",
  step: 0,
  success: false,
  business: "",
  coupon: "",
  new_order: false,
  clearLocal: () => {},
  submitted: false,
  setSubmitted: () => {},
  setSuccessOrder: () => {},
  latest: false,
});
export const useWorkflow = () => useContext(WorkflowContext);
const Workflow: NextPage<Props> = (props) => {
  const {
    success,
    step,
    aggregate,
    state,
    entity,
    business,
    plan,
    coupon,
    latest,
  } = props;
  const { push, pathname } = useRouter();
  const [cart, setCart] = useState<Cart | undefined>(undefined);
  const [values, setValues] = useLocalStorage<anyObject>("formValues");
  const [orderId, setOrderId] = useLocalStorage<string>("order_id");
  const [total, setTotal] = useState(0);
  const [maxPage, setMaxPage] = useLocalStorage("maxPage", 0);
  const [order, setOrder] = useState<Order>();
  const [successOrder, setSuccessOrder] = useState<Order>();
  const [states, setStates] = useLocalStorage<State[]>("states", []);
  const [products, setProducts] = useLocalStorage<Product[]>("products", []);
  const [pending, setPending] = useState<AddOn[]>([]);
  const [prevProduct, setPrevProduct] = useState("");
  const [clearOrderId, setClearOrderId] = useState(false);
  const {
    idToken,
    user,
    setSessionsId,
    setSessionsToken,
    sessionsChange,
    sessionsToken,
    sessionsId,
    safeLogout,
  } = useUser();
  const [userChanged, setUserChanged] = useState(false);
  const [skipped, setSkipped] = useLocalStorage<string[]>("skipped", []);
  const [redirectStep, setRedirectStep] = useState(NaN);
  const [rememberCoupon, setRememberCoupon] = useLocalStorage("rememberCoupon");
  const [submitted, setSubmitted] = useLocalStorage("submitted", false);

  const clearLocal = () => {
    setOrderId(undefined);
    setSkipped([]);
    setMaxPage(0);
    setValues({});
    typeof window !== "undefined" && localStorage.removeItem("business_name");
  };

  useEffect(() => {
    if (step < 2) return;
    if (maxPage + 2 < step) {
      push({ pathname, query: { step: maxPage + 1 } });
    }
  }, [maxPage, step]);

  useEffect(() => {
    if (success || !submitted) return;
    clearLocal();
    setSubmitted(false);
  }, [submitted, success]);

  useEffect(() => {
    if (coupon) setRememberCoupon(coupon);
  }, [setRememberCoupon, coupon]);

  useEffect(() => {
    const thisState = states?.find((s) => s.state_abbreviation === state);
    setValues((p) => ({
      ...p,
      plan_state: thisState
        ? thisState.state_name + "+++" + thisState.ID
        : p?.plan_state,
      entity: allowedEntities.includes(entity?.toString())
        ? entity
        : entity === "DAO" &&
          (p?.plan_state?.split("+++")?.[0] === "Wyoming" || state == "WY")
        ? "DAO"
        : p?.entity,
      product:
        products?.find((p) => p.product_name === planIndex[plan])?.ID ||
        p?.product,
    }));
  }, [state, entity, states, products]);

  useEffect(() => {
    if (business) setValues((p) => ({ ...p, business_name: business }));
  }, [business]);

  const order_id = getLocal("order_id");
  useEffect(() => {
    if (step > 2 && !order_id) {
      clearLocal();
      push({ pathname, query: { step: 0 } });
    }
  }, [order_id, step]);

  useEffect(() => {
    if (userChanged && !idToken) {
      clearLocal();
      push({ pathname, query: { step: 0 } });
    }
    setUserChanged(true);
  }, [idToken]);

  useEffect(() => {
    if (!(sessionsId && sessionsToken && orderId)) return;
    fetchPaymentStatus().then(({ body, error }) => {
      if (error?.response?.data?.msg === "Invalid or expired Cookie")
        safeLogout();
      if (clearOrderId) {
        setOrderId(undefined);
        setSkipped([]);
        setClearOrderId(false);
      }
      if (!success && body?.status_complete) {
        clearLocal();
        push(step > 4 ? "/" : { pathname, query: { step: 0 } });
      }
    });
  }, [success, step, clearOrderId]);

  useEffect(() => {
    if ((!sessionsToken || !sessionsId) && step > 1) {
      push({ pathname, query: { step: 0 } });
      setOrderId(undefined);
      setSkipped([]);
      setMaxPage(0);
    }
  }, [sessionsToken, sessionsId, step]);

  useEffect(() => {
    setPrevProduct(values?.product);
    if (!values?.product || !prevProduct) return;

    // RUNS ONLY WHEN PRODUCT CHANGES
    // setValues((p) => ({
    //   product: p.product,
    //   plan_state: p.plan_state,
    //   entity: p.entity,
    //   business_name: p.business_name,
    // }));
    setClearOrderId(true);
    setMaxPage(0);
  }, [values?.product]);

  // useEffect(() => {
  //   if (step > 2 && maxPage < step)
  //     push({ pathname, query: { step: maxPage } });
  // }, [step, maxPage]);

  // useEffect(()=>{},[success])

  const getPendingAddons = async () => {
    const data = await fetchPendingAddons();
    setPending(
      data.body?.add_ons?.map((i) => ({ changeKey: i.key, ...i.add_on }))
    );
  };

  const getCart = async (props?: anyObject) => {
    if (!sessionsId || !sessionsToken) return;
    if (!orderId && !props?.order_id) return;
    const { body, error } = await fetchCart(props);
    if (error?.response?.data?.msg === "Invalid or expired Cookie") {
      // CLEARING LOCAL STORAGE
      // setValues({});
      // setOrderId(undefined);
      // setSkipped([])
      // setSessionsId("");
      // setSessionsToken("");
      // setMaxPage(0);
    }
    setCart(body);
  };

  const getStates = () => fetchStates().then((res) => setStates(res.body));
  const getProducts = () =>
    fetchProducts().then((res) => setProducts(res.body));

  const [servicesDetails, setServicesDetails] = useState([]);
  const getServicesDetails = async () => {
    const data = await fetchAddons();
    setServicesDetails(
      data.body?.add_ons?.map((i) => ({ changeKey: i.key, ...i.add_on }))
    );
  };
  const getHistory = async () => {
    if (!orderId || !sessionsId || !sessionsToken) return setMaxPage(0);
    const data = await fetchHistory();
    if (data.body?.Max !== undefined) {
      const { Max } = data.body;
      const mp = Max < 4 ? Max : Max < 7 ? 3 : Max - 4;
      setMaxPage(mp);
      if (latest) push({ pathname, query: { step: mp } });
    }
  };
  useEffect(() => {
    getServicesDetails();
    getStates();
    getProducts();
  }, []);

  const addQueryCoupon = async () => {
    if (order.coupon_code)
      await removeCoupon({ coupon_code: order.coupon_code });
    const { error } = await postCoupon({ coupon_code: rememberCoupon });
    if (!error) toast.success(`Coupon code : ${rememberCoupon} applied!`);
    setRememberCoupon("");
    if (error) return;
    getPendingAddons();
    getCart();
    getHistory();
    getOrder();
  };

  useEffect(() => {
    if (!order) return;
    if (order.user?.email && user?.email && order.user?.email !== user?.email) {
      clearLocal();
      push({ pathname, query: { step: 0 } });
    }

    if (rememberCoupon && order.coupon_code !== rememberCoupon) {
      addQueryCoupon();
    }

    const [firstName, ...restName] = order.user?.full_name.split(" ");
    const lastName = restName.join(" ");

    setValues((p) => ({
      ...p,
      business_name: order.company_name || p.business_name,
      category: order.company_category || p.category,
      description: order.company_description || p.description,
      designator: order.designator || p.designator,
      if_corporate_documents: order.if_corporate_documents,
      if_ein_number: order.if_ein_number,
      if_express_service: order.if_express_service,
      if_license_service: order.if_license_service,
      if_professional_address: order.if_professional_address,
      if_registered_agent: order.if_registered_agent,
      email: order.user?.email || p.email,
      firstName: firstName || p.firstName,
      lastName: lastName || p.lastName,
    }));
    setTotal(order.coupon_code ? order.price_after_coupon : order.price);
  }, [order]);

  useEffect(() => {
    const { plan_state, entity, product, firstName } = values || {};
    const filled = {
      0: Boolean(plan_state && entity && product),
      1: Boolean(firstName),
    };
  }, [values]);

  const Component = steps?.[step]?.Component || ((() => <></>) as FC);
  const changeValue = (key: string) => (value: any) =>
    setValues((p) => ({ ...p, [key]: value }));

  useEffect(() => {
    getOrder();
    getHistory();
    getCart();
  }, [orderId, sessionsChange, step]);

  const getOrder = async () => {
    if ((!orderId || !sessionsId || !sessionsToken) && !success) return;
    const data = await fetchOrder();
    setOrder(data.body);
  };

  useEffect(() => {
    const entity_map = {
      LLC: "price_llc",
      C: "price_c_corp",
      S: "price_s_corp",
      NP: "price_non_profit",
      DAO: "price_dao",
    };
    setValues((p) => ({
      ...p,
      state_fee: states?.find(
        (s) => s.ID === values?.plan_state?.split("+++")?.[1]
      )?.[entity_map?.[values?.entity]],
    }));
  }, [values?.entity, values?.plan_state, states, step]);

  const stripePromise = useMemo(
    () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_publishableKey),
    [process.env.NEXT_PUBLIC_STRIPE_publishableKey, loadStripe]
  );

  const Wrapper: FC = useMemo(
    () =>
      step > 0
        ? (props) => (
            <Elements
              {...props}
              options={{
                appearance: {
                  theme: "night",
                  rules: {
                    number: { background: "red" },
                  },
                },
              }}
              stripe={stripePromise}
            />
          )
        : Fragment,
    [step, stripePromise]
  );

  return (
    <Wrapper>
      <WorkflowContext.Provider
        value={{
          ...props,
          states,
          products,
          values,
          setValues,
          orderId,
          setOrderId,
          changeValue,
          order,
          servicesDetails,
          total,
          maxPage,
          getOrder,
          getHistory,
          getCart,
          cart,
          setMaxPage,
          pending,
          getPendingAddons,
          skipped,
          setSkipped,
          aggregate,
          redirectStep,
          setRedirectStep,
          clearLocal,
          submitted,
          setSubmitted,
          successOrder,
          setSuccessOrder,
        }}
      >
        <ModalProvider>
          {success ? (
            <Success />
          ) : (
            <>
              <Breadcrumbs
                maxPage={maxPage}
                steps={steps}
                onClick={async (step) => {
                  await push(`/workflow?step=${step}`);
                }}
                current={step}
              />
              {step ? (
                <Section className={classes.container}>
                  <div className={classes.flex}>
                    <div className={classes.form}>
                      <Component />
                    </div>
                    {step > 1 ? <OrderDetailsCard /> : <></>}
                  </div>
                </Section>
              ) : (
                <SelectPlan />
              )}
            </>
          )}
        </ModalProvider>
      </WorkflowContext.Provider>
    </Wrapper>
  );
};

export default Workflow;

Workflow.getInitialProps = async ({
  query: {
    success,
    step: stepStr,
    state = "",
    entity = "",
    plan = "",
    business = "",
    coupon = "",
    new: new_order = "",
    latest = "",
  },
}) => {
  const step = getInt(stepStr?.toString());
  const aggregate = step === 0 ? await fetchAggregates() : undefined;
  return {
    success: success ? JSON.parse(success.toString()) : undefined,
    step,
    aggregate: aggregate,
    state: state.toString(),
    entity: entity.toString(),
    plan: getInt(plan.toString()),
    business: business.toString(),
    coupon: coupon.toString(),
    new_order: getBool(new_order.toString()),
    latest: getBool(latest.toString()),
  };
};
