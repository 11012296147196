const isProduction = process.env.NEXT_PUBLIC_DEV_ENV === "prod";

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (typeof window === "undefined") return;
  if (!isProduction) return;
  window?.gtag?.("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
