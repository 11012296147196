import { ROUTES } from "config/api";
import { AddOn, Cart, Order } from "interfaces";
import { createFetcher } from "./helpers";

export const postWorkflow1 = createFetcher.post(ROUTES.WORKFLOW.STEP(1));
export const postWorkflow2 = createFetcher.post(ROUTES.WORKFLOW.STEP(2), {
  addSessionCreds: true,
});
export const postWorkflow3 = createFetcher.post(ROUTES.WORKFLOW.STEP(3), {
  addSessionCreds: true,
});
export const postWorkflow4 = createFetcher.post(ROUTES.WORKFLOW.STEP(4), {
  addSessionCreds: true,
});
export const postWorkflow5 = createFetcher.post(ROUTES.WORKFLOW.STEP(5), {
  addSessionCreds: true,
});
export const postWorkflow6 = createFetcher.post(ROUTES.WORKFLOW.STEP(6), {
  addSessionCreds: true,
});
export const postWorkflow7 = createFetcher.post(ROUTES.WORKFLOW.STEP(7), {
  addSessionCreds: true,
});
export const postWorkflow8 = createFetcher.post(ROUTES.WORKFLOW.STEP(8), {
  addSessionCreds: true,
});

export const postWorkflowRecurring = createFetcher.post(
  ROUTES.WORKFLOW.STEP("recurring"),
  { addSessionCreds: true }
);
export const postWorkflowAddons = createFetcher.post(
  ROUTES.WORKFLOW.STEP("addons"),
  { addSessionCreds: true }
);

export const fetchOrder = createFetcher.post<Order>(ROUTES.WORKFLOW.ORDER, {
  addSessionCreds: true,
});
export const fetchPendingAddons = createFetcher.post<{
  add_ons: { key: string; add_on: AddOn }[];
}>(ROUTES.WORKFLOW.PENDING_ADDONS, { addSessionCreds: true });
export const fetchHistory = createFetcher.post(ROUTES.WORKFLOW.HISTORY, {
  addSessionCreds: true,
});
export const fetchCart = createFetcher.post<Cart>(ROUTES.WORKFLOW.CART, {
  addSessionCreds: true,
});
export const postCoupon = createFetcher.post<{ msg: string; order: Order }>(
  ROUTES.WORKFLOW.ADD_COUPON,
  { addSessionCreds: true }
);
export const removeCoupon = createFetcher.post<{ msg: string; order: Order }>(
  ROUTES.WORKFLOW.REMOVE_COUPON,
  { addSessionCreds: true }
);
export const orderSuccess = createFetcher.post(ROUTES.WORKFLOW.SUCCESS, {
  addSessionCreds: true,
});
