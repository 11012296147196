import { PlusWhite, ShopperApproved } from "assets";
import Button from "components/Button";
import Form from "components/Form";
import Plans from "components/Workflow/Plans";
import Loader from "components/Loader";
import Meta from "components/Meta";
import Section from "components/Section";
import { Modal } from "contexts/modal";
import { useScroll } from "contexts/scroll";
import useForm from "hooks/useForm";
import { FormProp } from "interfaces";
import { useWorkflow, WorkflowContext } from "pages/workflow";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import classes from "styles/components/Workflow/SelectPlan.module.scss";
import { event } from "utils/gtag";
import { spreadArray } from "utils/spreadIf";
import { object, string } from "yup";
import { services } from "./OrderDetailsCard";

const DAOstates = ["Wyoming"];

const schema = object().shape({
  plan_state: string().required(),
  entity: string().required(),
});

export default function SelectPlan() {
  const { createScroller, scrollTo } = useScroll();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    states,
    setValues,
    values,
    aggregate,
    redirectStep,
    new_order,
    order,
  } = useWorkflow();
  const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);
  const currentState: string = useMemo(
    () => values?.plan_state?.split("+++")[0] || "",
    [values?.plan_state]
  );

  const openStateEntityModal = () => setModalOpen(true);

  useEffect(() => {
    if (order && new_order) setNewOrderModalOpen(true);
  }, [order]);

  useEffect(() => {
    event({ action: "workflow_step_0_view" });
  }, []);

  useEffect(() => {
    if (values?.entity === "DAO" && !DAOstates.includes(currentState))
      setValues((p) => ({ ...p, entity: "LLC" }));
  }, [currentState]);

  const plansRef = useRef(null);
  useEffect(() => {
    createScroller("plans", plansRef);
  }, []);
  useEffect(() => {
    if (!values?.plan_state || !values?.entity) return;
    scrollTo("plans");
  }, [values?.plan_state, values?.entity]);

  const entries = useMemo(
    () => [
      [
        {
          name: "plan_state",
          label: "State",
          type: "select",
          placeholder: "Select State",
          options:
            states?.map((state) => ({
              value: state.state_name + "+++" + state.ID,
              label: `${state.state_abbreviation} - ${state.state_name}`,
              searchKeys: [state.state_abbreviation],
            })) || [],
          searchable: true,
          preview: (str: string) => str?.split("+++")[0],
        },
        {
          name: "entity",
          label: "Entity",
          type: "select",
          placeholder: "Select Entity",
          options: [
            { value: "LLC" },
            { value: "C", label: "C - Corporation" },
            { value: "S", label: "S - Corporation" },
            { value: "NP", label: "Non - profit" },
            ...spreadArray(
              { value: "DAO", label: "DAO LLC" },
              DAOstates.includes(currentState)
            ),
          ],
        },
      ],
    ],
    [states, currentState]
  );

  const { formProps } = useForm({ redirectStep, entries, values });

  return (
    <>
      <Meta
        tags={{
          title:
            "Business registration pricing: LLC, Corp, S-Corp, Non-Profit | IncDecentral",
          description:
            "Our Incorporation Plan offers complete business incorporation plan you'll find, and our price is hard to beat. Start incorporation at affordable price of $19 + state fees",
        }}
      />
      <Section
        className={classes.container}
        innerClassName={classes.innerClassName}
      >
        <>
          <div className={classes.head}>
            <h1 className={classes.title}>
              Business filings made faster and easier
            </h1>
            <h3 className={classes.subTitle}>
              Start incorporating your company by choosing{" "}
              <b>state of formation</b> and <b>entity type</b>
            </h3>
            {!entries?.[0]?.[0]?.options?.length && (
              <Loader
                messages={[
                  "Setting up IncD genie",
                  "Creating workflow",
                  "Eager to incorporate?",
                ]}
                style={{ margin: `20px 0` }}
              />
            )}
            {!!states?.length && (
              <Form context={WorkflowContext} {...formProps} />
            )}
            {aggregate && (
              <a
                href={`https://www.shopperapproved.com/reviews/${aggregate.site_label}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={classes.trust}>
                  <div className={classes.shopper}>
                    <ShopperApproved />
                    <p>
                      Rated <span>{aggregate.average_rating}</span> out of 5 by{" "}
                      {aggregate.total_reviews} users
                    </p>
                  </div>
                  <span></span>
                  <div className={classes.testimony}>
                    <p>
                      “Really easy to use and great communication between myself
                      and the lawyer.”
                    </p>
                  </div>
                </span>
              </a>
            )}
          </div>
        </>
      </Section>
      <div ref={plansRef}>
        <Plans openStateEntityModal={openStateEntityModal} />
      </div>
      <NewOrderModal
        isOpen={newOrderModalOpen}
        onClose={() => setNewOrderModalOpen(false)}
      />
      <StateEntityModal
        entries={entries}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

interface NewOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewOrderModal = ({ ...props }: NewOrderModalProps) => {
  const { clearLocal, order, cart, values } = useWorkflow();
  if (!order) return <></>;
  return (
    <Modal className={classes.newOrder} {...props}>
      <div>
        <h1>
          Hi {order.user?.full_name},
          <br />
          You already have an existing order with us
        </h1>
        <div className={classes.cards}>
          <div className={[classes.card, classes.old].join(" ")}>
            {values?.business_name && cart && (
              <div className={classes.business}>
                <p>
                  {values.business_name} {values.designator}
                </p>
                <p>${cart.BasePrice.Total}</p>
              </div>
            )}
            {order.products.map((item) => (
              <div className={classes.flex} key={item.ID}>
                <p>{item.product_name}</p>
                <p>${item.product_price}</p>
              </div>
            ))}
            {cart?.AddOns?.map((addon) => {
              const { includedIn } = services.find(
                (s) => s.add_on_name === addon.add_on_name
              );
              return (
                <div className={classes.flex} key={addon.ID}>
                  <p>{addon.add_on_name}</p>
                  <p>
                    {includedIn?.includes(order?.products?.[0].product_name)
                      ? "FREE"
                      : `$${addon.add_on_price}`}
                  </p>
                </div>
              );
            })}
            <Button
              theme="secondary"
              variant="outlined"
              onClick={props.onClose}
            >
              Continue with this order
            </Button>
          </div>
          <div
            onClick={() => {
              clearLocal();
              props.onClose();
            }}
            className={[classes.card, classes.new].join(" ")}
          >
            <div className={classes.plusIcon}>
              <PlusWhite />
            </div>
            <p>Create new order</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const StateEntityModal = ({
  entries,
  isOpen,
  onClose,
}: {
  entries: FormProp[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { values, redirectStep, setRedirectStep } = useWorkflow();
  const { formProps } = useForm({
    redirectStep,
    setRedirectStep,
    schema,
    entries,
    onSubmit: () => {
      const { entity, plan_state } = values || {};
      if (!entity || !plan_state) toast.error("Please select entity and state");
      else {
        // push({ pathname: "/workflow", query: { step: 1 } });
        onClose();
        toast.info(
          "State and Entity selected. Please confirm your plan selection"
        );
      }
    },
    values,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <h2>Please select State and Entity</h2>
        <Form dropHeight={240} context={WorkflowContext} {...formProps}>
          <div className={classes.button}>
            <Button theme="secondary">Continue</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
