import { useRouter } from "next/router";

export default function useNav() {
  const {
    pathname,
    query: { step: stepStr = "0" },
    push,
  } = useRouter();
  const step = parseInt(stepStr?.toString());

  return {
    toStep: (to: ((p: number) => number | void) | number) =>
      to !== undefined &&
      to !== null &&
      (() => {
        const s = push({
          pathname,
          query: {
            step: to instanceof Function ? to(step) || step : to,
          },
        });
      }),
  };
}
