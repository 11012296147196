import { ROUTES } from "config/api";
import { AddOn, Product, State } from "interfaces";
import { createFetcher } from "./helpers";

export const fetchStates = createFetcher.get<State[]>(ROUTES.CONSTANTS.STATES);
export const fetchProducts = createFetcher.get<Product[]>(
  ROUTES.CONSTANTS.PRODUCTS
);
export const fetchAddons = createFetcher.get<{
  add_ons: { key: string; add_on: AddOn }[];
}>(ROUTES.CONSTANTS.ADDONS);
