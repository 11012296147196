import { CONTACT_NUMBER } from "config/constants";
import Link from "next/link";

export default function() {
  return (
    <>
      <p>
        <b>
          <u>
            <span>Terms and Conditions </span>
          </u>
        </b>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <span>
            PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT (“AGREEMENT”)
            CAREFULLY. BY ACCESSING OR USING OUR WEBSITE (THE “SITE”) OR OUR
            SERVICES, YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS
            AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE
            RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER
            TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE.
            IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS,
            THEN PLEASE DO NOT ACCESS OR USE OUR SITE OR SERVICES.
          </span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>Overview</span>
          </u>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>
              <span>&nbsp;</span>
            </span>
          </u>
        </b>
      </p>

      <p>
        <span>The Site (</span>
        <span>
          <a>
            <span>incdecentral.com</span>
          </a>
        </span>
        <span>
          ) is operated and owned by the law firm LegalForce RAPC Worldwide
          P.C., dba Trademarkia P.C (“Trademarkia”). Throughout the Site, the
          terms “we”, “us” and “our” refer to Trademarkia. Trademarkia offers
          this Site, including all information, tools and services available
          from this Site to you, the user, conditioned upon your acceptance of
          all terms, conditions, policies and notices stated here.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          By visiting our Site and/or purchasing something from us, you engage
          in our “Services” and agree to be bound by the following terms and
          conditions (“Terms”), including those additional terms and conditions
          and policies referenced herein and/or available by hyperlink. These
          Terms apply to all users of the Site, including without limitation
          users who are browsers, venders, customers, merchants, and/or
          contributors of content. In the event of an inconsistency between this
          Terms of Service Agreement and any additional terms or policies
          referenced herein, the provisions of the additional terms or policies
          shall control.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Any new features or tools which are added to the current website shall
          also be subject to the Terms. You can review the most current version
          of the Terms at any time on this page. We reserve the right to update,
          change, or replace any part of these Terms by posting updates and/or
          changes. Your continued use of or access to the Site following the
          posting of any changes constitutes acceptance of those changes.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 1</span>
          </u>
        </b>
        <b>
          <span>- GENERAL TERMS</span>
        </b>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          By agreeing to these Terms, you represent that you are at least the
          age of majority in your state or province of residence and you are
          legally able to enter into a contract.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Visiting our Site or sending emails constitutes electronic
          communication. You consent to receive electronic communications and
          you agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically, via email and on
          the Site, satisfy any legal requirement that such communications be in
          writing. 
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You may not use our Site for any illegal or unauthorized purposes nor
          may you, in the use of our Site, violate any laws in your jurisdiction
          (including but not limited to motor vehicle laws).
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You must not transmit any worms or viruses or any code of a
          destructive nature.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          A breach or violation of any of the Terms will result in an immediate
          termination of your account and right to use our Services.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          We have the right, but not the obligation, to take any of the
          following actions in our sole discretion at any time and for any
          reason without giving you and prior notice:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Restrict, suspend, or terminate your access to all or any part of
            our Site;
          </span>
        </li>
        <li>
          <span>
            Change, suspend, or discontinue all or any part of our products or
            Site;
          </span>
        </li>
        <li>
          <span>
            Refuse, move, or remove any content that is available on all or any
            part of our Site;
          </span>
        </li>
        <li>
          <span>Deactivate or delete your accounts;</span>
        </li>
        <li>
          <span>
            Establish general practices and limits concerning use of our Site.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You agree that we will not be liable to you or any third party for
          taking any of these actions.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You understand and agree that our Site may include communication such
          as a service announcement and administrative or legal notices from us.
          Please note that you cannot opt out of receiving these notices.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You understand that your content (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over
          networks.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Site, use of the Site, or access to the Site or any
          contact on the Site, without express written permission by us.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          You may not modify, publish, transmit, reverse engineer, participate
          in the transfer or sale, create derivative works, or in any way
          exploit any of the content, in whole or in part, found on the Site.
          Trademarkia content is not for resale. Use of the Site does not
          entitle users to make any unauthorized use of any protected content,
          and in particular you will not delete or alter any proprietary rights
          or attribution notices in any content. You will use protected content
          solely for your personal use, and will make no other use of the
          content without the express written permission of Trademarkia and the
          copyright owner. You agree that you do not acquire any ownership
          rights in any protected content. We do not grant you any license,
          express or implied, to the intellectual property of Trademarkia or our
          licensors except as expressly authorized by these Terms.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 2</span>
          </u>
        </b>
        <b>
          <span>- CREATING AN ACCOUNT</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>Once you create an account with us, you are registered on </span>
        <span>
          <a>
            <span>www.incdecentral.com</span>
          </a>
        </span>
        <span>
          . The terms “member”, “membership”, and “account” all refer to this
          registration as a member on <span>IncDecentral (Trademarkia’s </span>
          Site). If you are merely surfing on browsing through the Site and have
          not yet created an account, your use of the Site is still subject to
          these Terms; if you do not agree to this Agreement, do not use the
          Site.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          When you create an account, you will provide a unique username and
          email. We will also ask you to create a password. Any activity that
          occurs under your username or password is your responsibility. It is
          important for you to keep your username and password secure. You may
          not assign or otherwise transfer your account to any other person or
          entity. You acknowledge that Trademarkia is not responsible for third
          party access to your account that results from theft or
          misappropriation of your account. Notify us immediately if you believe
          that someone has used your username, email, or password without your
          authorization.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Furthermore, the registering party hereby acknowledges, understands
          and agrees to:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Furnish factual, correct, current, and complete information with
            regards to yourself as may be requested by the data registration
            process, and
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Maintain and promptly update your registration and profile
            information in an effort to maintain accuracy and completeness at
            all times.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          If you knowingly provides any information of a false, untrue,
          inaccurate or incomplete nature, Trademarkia will have sufficient
          grounds and rights to suspend or terminate you in violation of this
          aspect of the Agreement, and as such refuse any and all current or
          future use of Trademarkia’s Services, or any portion thereof.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 3</span>
          </u>
        </b>
        <b>
          <span>- NATURE OF GOVERNMENT DATA DISPLAYED ON SITE</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Public data displayed on the Site are from government records.
          Trademarkia does its best to make sure the data is current and
          accurate but cannot guarantee the accuracy of the data on the Site.
          Sometimes, individual records on the Site are removed or masked at the
          owner’s request. Furthermore, due to refresh delays, Trademarkia
          cannot guarantee that all the information on the Site is completely
          current.
        </span>
      </p>

      <p>
        <b>
          <u>
            <span>
              <span>&nbsp;</span>
            </span>
          </u>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 4</span>
          </u>
        </b>
        <b>
          <span>- CONDUCT</span>
        </b>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          As a user or member of the Site, you herein acknowledge, understand
          and agree that all information, text, software, data, photographs,
          music, video, message, tags or any other content, whether it is
          publicly or privately posted and/or transmitted, is the expressed sole
          responsibility of the individual from whom the content originated.
          This means that you are solely responsible for any and all content
          posted, uploaded, emailed, transmitted or otherwise made available by
          way of the Trademarkia Services, and as such, we do not guarantee the
          accuracy, integrity or quality of such content. It is expressly
          understood that by use of our Services, you may be exposed to content
          including, but not limited to, any errors or omissions in any content
          posted, and/or any loss or damage of any kind of incurred as a result
          of the use of any content posted, emailed, transmitted or otherwise
          made available by Trademarkia.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Furthermore, you herein agree not to make use of Trademarkia’s
          Services for the purpose of:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Uploading, posting, emailing, transmitting, or otherwise making
            available any content that shall be deemed unlawful, threatening,
            abusive, harassing, and tortious, defamatory, vulgar, obscene,
            libelous, or invasive of another’s privacy or which is hateful,
            and/or racially, ethnically, or otherwise objectionable;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>Causing harm to minors in any manner whatsoever;</span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Impersonating any individual or entity, including, but not limited
            to, any Trademarkia officials, forum leaders, guides or hosts or
            falsely stating or otherwise misrepresenting any affiliation with an
            individual or entity;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Forgoing captions, headings or titles or otherwise offering any
            content that you personally have no right to pursuant to any law nor
            having any contractual or fiduciary relationship with;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Uploading, posting, emailing, transmitting or otherwise offering any
            such content that may infringe upon any patent, copyright,
            trademark, or any other proprietary or intellectual rights of any
            other party;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Uploading, posting, emailing, transmitting or otherwise offering any
            content that you do not personally have any right to offer pursuant
            to any law or in accordance with any contractual or fiduciary
            relationship;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Uploading, posting, emailing, transmitting or otherwise offering any
            unsolicited or unauthorized advertising, promotional flyers, “junk
            mail”, “spam”, or any other form of solicitation, except in any such
            areas that may have been designated for such purpose;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Uploading, posting, emailing, transmitting or otherwise offering any
            source that may contain a software virus or other computer code, any
            files and/or programs which have been designed to interfere, destroy
            and/or limit the operation of any computer software, hardware, or
            telecommunication equipment;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Disrupting the normal flow of communication, or otherwise acting in
            any manner that would negatively affect other users’ ability to
            participate in any real time interactions;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Interfering with or disrupting any Trademarkia Services, servers
            and/or networks that may be connected or related to our website,
            including, but not limited to, the use of any device software and/or
            routine to bypass the robot exclusion headers;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Intentionally or unintentionally violating any local, state,
            federal, national or international law, including, but not limited
            to, rules, guidelines, and/or regulations decreed by the U.S.
            Securities and Exchange Commission, in addition to any rules of any
            nation or other securities exchange, that would include without
            limitation, the New York Stock Exchange, the American Stock
            Exchange, or the NASDAQ, and any regulations having the force of
            law;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Providing informational support or resources, concealing and/or
            disguising the character, location, and or source to any
            organization delegated by the United States government as a “foreign
            terrorist organization” in accordance to Section 219 of the
            Immigration Nationality Act;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            “Stalking” or with the intent to otherwise harass another
            individual; and/or
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Collecting or storing of any personal data relating to any other
            member or user in connection with the prohibited conduct and/or
            activities which have been set forth in the aforementioned
            paragraphs.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Trademarkia herein reserves the right to pre-screen, refuse and/or
          delete any content currently available through our Services. In
          addition, we reserve the right to remove and/or delete any such
          content that would violate the Terms or which would otherwise be
          considered offensive to other visitors, users, and/or members.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Trademarkia herein reserves the right to access, preserve and/or
          disclose member account information and/or content if it is requested
          to do so by law or in good faith belief that any such action is deemed
          reasonably necessary for:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>Compliance with any legal process;</span>
        </li>
        <li>
          <span>Enforcement of the Terms;</span>
        </li>
        <li>
          <span>
            Responding to any claim that therein contained content is in
            violation of the rights of any third party;
          </span>
        </li>
        <li>
          <span>Responding to request for customer service; or</span>
        </li>
        <li>
          <span>
            Protecting the rights, property or the personal safety of
            Trademarkia, its visitors, users and members, including the general
            public.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Trademarkia herein reserves the right to include the use of security
          components that may permit digital information or material to be
          protected, and that such use of information and/or material  is
          subject to usage guidelines and regulations established by Trademarkia
          or any other content providers supplying content services to
          Trademarkia. You are hereby prohibited from making any attempt to
          override or circumvent any of the embedded usage rules in our
          Services. Furthermore, unauthorized reproduction, publication,
          distribution, or exhibition of any information or materials supplied
          by our Services, despite whether done so in whole or in part, is
          expressly prohibited.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 5</span>
          </u>
        </b>
        <b>
          <span>- GLOBAL USE; EXPORT/IMPORT COMPLIANCE</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Due to the global nature of the internet, through the use of our
          network you hereby agree to comply with all local rules relating to
          online conduct and that which is considered acceptable content.
          Uploading, posting, and/or transferring of software, technology and
          other technical data may be subject to the export and important laws
          of the United States and possibly other countries. Through the use of
          our network, you thus agree to comply with all applicable export and
          import laws, statutes and regulations, including, but not limited to,
          the Export Administration Regulations, as well as the sanctions
          control program of the United States. Furthermore, you state and
          pledge that you:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Are not on the list of prohibited individuals which may be
            identified on any government export exclusion report nor a member of
            any other government which may be part of an export-prohibited
            country identified in applicable export and import laws and
            regulations;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Agree not to transfer any software, technology or any other
            technical data through the use of our network Services to any
            export-prohibited country;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Agree not to use our website network Services for any military,
            nuclear, missile, chemical or biological weaponry end uses that
            would be a violation of the U.S. export laws;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Agree not to post, transfer nor upload any software, technology or
            any other technical data which would be in violation of the U.S. or
            other applicable export and/or import laws.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 6</span>
          </u>
        </b>
        <b>
          <span>- SUBMITTED CONTENT</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia shall not lay claim to ownership of any content,
          <span>
            including but not limited to comments, reviews, files, and/or
            documents
          </span>
          , submitted by any visitor, member, or user, nor make such content
          available for inclusion on our website or in our Services. Therefore,
          you hereby grant and allow for Trademarkia the below listed worldwide,
          royalty-free and non-exclusive licenses, as applicable:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            The content submitted or made available for inclusion on the
            publicly accessible areas of Trademarkia’s Sites, the license
            provided to permit to use, distribute, reproduce, modify, adapt,
            publicly perform and/or publicly display said “Content” on our
            network Services is the sole purpose of providing and promoting the
            specific area to which this Content was placed and/or made available
            for viewing. This license shall be available so long as you are a
            member of Trademarkia’s Sites, and shall terminate at such time when
            you elect to discontinue your membership.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Photos, audio, video and/or graphics submitted or made available for
            inclusion on the publicly accessible areas of Trademarkia’s Sites,
            the license provided to permit to use, distribute, reproduce,
            modify, adapt, publicly perform and/or publicly display said Content
            on our network Services are for the sole purpose of providing and
            promoting the specific area in which this Content was placed and/or
            made available for viewing. This license shall be available so long
            as you are a member of Trademarkia’s Sites and shall terminate at
            such time when you elect to discontinue your membership.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            For any other Content submitted or made available for inclusion on
            the publicly accessible area of Trademarkia’s Sites, the continuous,
            binding and completely sub-licensable license which is meant to
            permit to use, distribute, reproduce, modify, adapt, publish,
            translate, publicly perform and/or publicly display said content,
            whether in whole or in part, and the incorporation of any such
            Content into other works in any arrangement or medium current used
            or later developed.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Those areas which may be deemed “publicly accessible” areas of our
          Sites are those such areas of our network properties which are meant
          to be available to the general public, and which would include message
          boards and groups that are openly available to both users and members.
          However, those areas which are not open to the public, and thus
          available to members only, would include our mail system and instant
          messaging.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>CONTRIBUTIONS TO COMPANY WEBSITE</span>
          </u>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>
              <span>&nbsp;</span>
            </span>
          </u>
        </b>
      </p>

      <p>
        <span>
          Trademarkia may provide an area for our users and members to
          contribute feedback to our website. When you submit ideas, documents,
          suggestions and/or proposals (“Contributions”) to our site, you
          acknowledge and agree that:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Your Contributions do not contain any type of confidential or
            proprietary information;
          </span>
        </li>
        <li>
          <span>
            Trademarkia shall not be liable or under any obligation to ensure or
            maintain confidentiality, expressed or implied, related to any
            Contributions;
          </span>
        </li>
        <li>
          <span>
            Trademarkia shall be entitled to make use of and/or disclose any
            such Contributions in any such manner as they may see fit;
          </span>
        </li>
        <li>
          <span>
            The contributor’s Contribution shall automatically become the sole
            property of Trademarkia; and
          </span>
        </li>
        <li>
          <span>
            Trademarkia is under no obligation to either compensate or provide
            any form of reimbursement in any manner or nature.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 7</span>
          </u>
        </b>
        <b>
          <span>- UNSOLICITED SUBMISSIONS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia does not want you to submit your confidential or
          proprietary information through the Site, other than the materials
          necessary for placing orders on the Site to seek Trademarkia’s legal
          services. All comments, feedback, information or material submitted to
          the Firm through or in association with this Site, which is not for
          the purposes of soliciting legal services for an attorney, shall be
          considered non-confidential and Trademarkia’s property. By providing
          such submissions to Trademarkia you hereby assign to the Trademarkia,
          at no charge, all worldwide right, title and interest in and to the
          submissions and any intellectual property rights associated therewith.
          Trademarkia shall be free to use and/or disseminate such submissions
          on an unrestricted basis for any purpose. You acknowledge that you are
          responsible for the submissions that you provide, including their
          legality, reliability, appropriateness, originality and content.
        </span>
      </p>

      <p>
        <b>
          <u>
            <span>
              <span>&nbsp;</span>
            </span>
          </u>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 8</span>
          </u>
        </b>
        <b>
          <span>- INDEMNITY</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          All users and/or members agree to insure, defend, indemnify and hold
          harmlessTrademarkia, our subsidiaries, affiliates, agents, employees,
          officers, directors, shareholders, partners and/or licensors from any
          and all claims, liabilities, damages, losses or expenses, which may
          include, but is not limited to, reasonable attorney fees made by any
          third party which may arise from any content a member or user of our
          Site may submit, post, modify, transmit or otherwise make available
          through our Services, or your connection with these Services, your
          violations of the Terms and/or your violation of any such rights of
          another person.
        </span>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 9</span>
          </u>
        </b>
        <b>
          <span>- COMMERCIAL REUSE OF SERVICE</span>
        </b>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          The member or user herein agrees not to replicate, duplicate, copy,
          trade, sell, resell nor exploit for any commercial reason any part,
          use of, or access to our Sites.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 10</span>
          </u>
        </b>
        <b>
          <span>- MODIFICATIONS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia reserves the right at any time it may deem fit, to modify,
          alter and or discontinue, whether temporarily or permanently, our
          Services, or any part thereof, with or without prior notice. In
          addition, we shall not be held liable to you or to any third party for
          any such alteration, modification, suspension and/or discontinuance of
          our Services, or any part thereof.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 11</span>
          </u>
        </b>
        <b>
          <span>- TERMINATION</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>As a member of </span>
        <span>
          <a>
            <span>www.incdecentral.com</span>
          </a>
        </span>
        <span>
          , you may cancel or terminate your account, associated email address
          and/or access to our Services by submitting a cancellation or
          termination request to the Site.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          As a member, you agree that <span>Trademarkia </span>may, without any
          prior written  notice, immediately suspend, terminate, discontinue
          and/or limit your account, any email associated with your account, and
          access to any of our Services. The cause for such termination,
          discontinuance, suspension and/or limitation of access shall include,
          but is not limited to:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Any breach or violation of our Terms or any other incorporated
            agreement, regulation and/or guideline;
          </span>
        </li>
        <li>
          <span>
            By way of requests from law enforcement or any other governmental
            agencies;
          </span>
        </li>
        <li>
          <span>
            The discontinuance, alteration and/or material modification to our
            Services, or any part thereof;
          </span>
        </li>
        <li>
          <span>Unexpected technical or security issues and/or problems;</span>
        </li>
        <li>
          <span>Any extended periods of inactivity;</span>
        </li>
        <li>
          <span>
            Any engagement by you in any fraudulent or illegal activities;
            and/or
          </span>
        </li>
        <li>
          <span>
            The nonpayment of any associated fees that may be owed by you in
            connection with your <span>IncDecentral </span>account Services.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Furthermore, you herein agree that any and all termination,
          suspension, discontinuances, and/or limitations of access for cause
          shall be made at our sole discretion and that we shall not be liable
          to you or any other third party with regards to the termination of
          your account, associated email address and/or access to any of our
          Services.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>The termination of your account with </span>
        <span>
          <a>
            <span>www.incdecentral.com</span>
          </a>
        </span>
        <span>shall include any and/or all of the following:</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            The removal of any access to all or part of the Services offered
            within
            <span>the Site</span>;
          </span>
        </li>
        <li>
          <span>
            The deletion of your password and any and all related information,
            files, and any such content that may be associated with or inside
            your account, or any part thereof; and
          </span>
        </li>
        <li>
          <span>
            The barring of any further use of all or part of our Services. 
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 12</span>
          </u>
        </b>
        <b>
          <span>- LINKS</span>
        </b>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Either Trademarkia or any third parties may provide links to other
          websites and/or resources. Thus, you acknowledge and agree that we are
          not responsible for the availability of any such external sites or
          resources, and as such, we do not endorse nor are we responsible or
          liable for any content, products, advertising or any other materials,
          on or available from such third party sites or resources. Furthermore,
          you acknowledge and agree that Trademarkia shall not be responsible or
          liable, directly or indirectly, for any such damage or loss which may
          be a result of, caused or allegedly to be caused by or in connection
          with the use of or the reliance on any such content, goods or Services
          made available on or through any such site or resource.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 13</span>
          </u>
        </b>
        <b>
          <span>- PROPRIETARY RIGHTS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          You do hereby acknowledge and agree that Trademarkia’s Services and
          any essential software that may be used in connection with our
          Services (“Software”) shall contain proprietary and confidential
          material that is protected by applicable intellectual property rights
          and other laws. Furthemore, you herein acknowledge and agree that any
          Content which may be contained in any advertisements or information
          presented by and through our Services or by advertisers is protected
          by copyrights, trademarks, patents and/or other proprietary rights and
          laws. Therefore, except for that which is expressly permitted by
          applicable law or as authorized by Trademarkia or such applicable
          licensor, you agree not to alter, modify, lease, rent, loan, sell,
          distribute, transmit, broadcast, publicly perform and/or created any
          plagiaristic works which are based on Trademarkia’s Services (e.g.
          Content or Software), in whole or in part.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Trademarkia hereby grants you a personal, non-transferable and
          non-exclusive right and/or license to make use of the object code or
          our Software on a single computer, as long as you do not, and shall
          not, allow any third party to duplicate, alter, modify, create or
          plagiarize work from, reverse engineer, reverse assemble or otherwise
          make an attempt to locate or discern any source code, sell, assign,
          sublicense, grant a security interest in and/or otherwise transfer any
          such right in the Software. Furthermore, you do herein agree not to
          alter or change the Software in any manner, nature or form, and as
          such, not to use any modified versions of the Software, including and
          without limitation, for the purpose of obtaining unauthorized access
          to our Services. Lastly, you also agree not to access or attempt to
          access our Services through any means other than through the interface
          which is provided by Trademarkia for use in accessing our Services.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 14</span>
          </u>
        </b>
        <b>
          <span>- WARRANTY DISCLAIMERS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            THE USE OF TRADEMARKIA’S SERVICES AND SOFTWARE ARE AT THE SOLE RISK
            BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN “AS IS”
            AND/OR “AS AVAILABLE” BASIS. TRADEMARKIA AND OUR SUBSIDIARIES,
            AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
            EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER
            EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED
            WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND NON-INFRINGEMENT.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            TRADEMARKIA AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
            AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i)
            TRADEMARKIA’S SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii)
            TRADEMARKIA’S SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY,
            SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED
            FROM THE USE OF TRADEMARKIA’S SERVICES OR SOFTWARE WILL BE ACCURATE
            OR RELIABLE; (iv) QUALITY OF PRODUCTS, SERVICES, ANY INFORMATION OR
            OTHER MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR
            SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY
            SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY
            OF TRADEMARKIA’S SERVICES OR SOFTWARE SHALL BE ACCESSED BY YOUR SOLE
            DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY
            RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF
            ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET
            ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT
            COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL. 
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT
            MAY BE OBTAINED BY YOU FROM TRADEMARKIA OR BY WAY OF OR FROM OUR
            SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
            IN THE TERMS.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 15</span>
          </u>
        </b>
        <b>
          <span>- LIMITATION OF LIABILITY</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT TRADEMARKIA AND
          OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS
          AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING,
          BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY
          PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN
          THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES
          MAY OCCUR, AND RESULT FROM:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>THE USE OR INABILITY TO USE OUR SERVICES;</span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;</span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS
            AND/OR DATA;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE; AND
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.</span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 16</span>
          </u>
        </b>
        <b>
          <span>- RELEASE</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          In the event you have a dispute, you agree to release
          <span>www.incdecentral.com and </span>Trademarkia (and its officers,
          directors, employees, agents, parent subsidiaries, affiliates,
          co-branders, partners and any other third parties) from claims,
          demands and damages (actual and consequential) of every kind and
          nature, known and unknown, suspected or unsuspected, disclosed and
          undisclosed, arising out of or in any way connected to such dispute.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 17</span>
          </u>
        </b>
        <b>
          <span>- NOTICE</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia may furnish you notices, including those with regards to
          any changes to the Terms, including but not limited to email, regular
          mail, MMS or SMS, text messaging, postings on our website, or other
          reasonable means currently known or any which may be hereinafter
          developed. Any such notices may not be received if you violate any
          aspects of the Terms by accessing our Services in an unauthorized
          manner. Your acceptance of this Agreement constitutes your agreement
          that you are deemed to have received any and all notices that would
          have been delivered had you accessed our Services in an authorized
          manner.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 18</span>
          </u>
        </b>
        <b>
          <span>- INTELLECTUAL PROPERTY RIGHTS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          You herein acknowledge, understand and agree that all of the
          Trademarkia trademarks, copyrights, trade names, service marks, and
          other Trademarkia logos and any brand features, and/or product and
          service names are trademarks and as such, are and shall remain the
          property of Trademarkia. You herein agree not to display and/or use in
          any manner the Trademarkia logos or marks without obtaining
          Trademarkia’s prior written consent.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          Trademarkia will always respect the intellectual property of others,
          and we ask that all of our users do the same. With regards to
          appropriate circumstances and at its sole discretion, Trademarkia may
          disable and/or terminate the accounts of any user who violates our
          Terms and/or infringement, or if you believe your intellectual
          property rights have been otherwise violated, you should provide to us
          the following information:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            The electronic or the physical signature of the individual that is
            authorized on behalf of the owner of the copyright or other
            intellectual property interest;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            A description of the copyrighted work or other intellectual property
            that you believe has been infringed upon;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            A description of the location of the site which would allege has
            been infringing upon your work;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            Your physical address, telephone number, and email address;
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            A statement, in which you state that the allege and disputed use of
            your work is not authorized by the copyright owner, its agents or
            the law; and
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <ol>
        <li>
          <span>
            A statement, made under penalty of perjury, that the aforementioned
            information in your notice is truthful and accurate, and that you
            are the intellectual property owner, representative or agent
            authorized to act on the intellectual property owner’s behalf.
          </span>
        </li>
      </ol>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          The Trademarkia agent for notice of claims of copyright or other
          intellectual property infringement can be contacted as follows:
        </span>
      </p>

      <p>
        <b>
          <u>
            <span>
              <span>&nbsp;</span>
            </span>
          </u>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>Mailing Address:</span>
          </u>
        </b>
      </p>

      <p>
        <span>Trademarkia P.C.</span>
      </p>

      <p>
        <span>Att: Copyright Agent</span>
      </p>

      <p>
        <span>440 E. Southern Ave,</span>
      </p>

      <p>
        <span>Tempe AZ 85282</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>Telephone:</span>
          </u>
        </b>
      </p>

      <p>
        <span>{CONTACT_NUMBER.US_CANADA} (Toll Free U.S./Canada)</span>
      </p>

      <p>
        <span>{CONTACT_NUMBER.US} ( U.S. Local/International)</span>
      </p>

      <p>
        <span>{CONTACT_NUMBER.EU} ( Europe)</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>Email:</span>
          </u>
        </b>
      </p>

      <p>
        <span>
          <a>
            <span>customer.service@trademarkia.com</span>
          </a>
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 19</span>
          </u>
        </b>
        <b>
          <span>- ENTIRE AGREEMENT</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          This Agreement constitutes the entire agreement between you and
          Trademarkia and shall govern the use of our Services, superseding any
          prior version of this Agreement between you and us with respect to
          Trademarkia’s Services. You may also be subject to additional terms
          and conditions that may apply when you use or purchase certain other
          Trademarkia Services, affiliate Services, third party content or third
          party software.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 20</span>
          </u>
        </b>
        <b>
          <span>- CHOICE OF LAW AND FORUM</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          The parties shall be governed by the laws of the State of California
          without regard to its conflict of law provisions and that any and all
          claims, causes of action and/or disputes, arising out of or relating
          to the Agreement, or the relationship between you and Trademarkia,
          shall be filed within the courts having jurisdiction within the County
          of Santa Clara in California or the U.S. District Court located in
          said state. You agree to submit to the jurisdiction of the courts as
          previously mentioned, and agree to waive any and all objections to the
          exercise of jurisdiction over the parties by such courts and to venue
          in such courts.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 21</span>
          </u>
        </b>
        <b>
          <span>- BINDING ARBITRATION</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia does not anticipate having any disagreements with you
          regarding usage of this Site. But if any concerns about these matters
          arise, please notify us immediately. Trademarkia would endeavor to
          resolve any disagreements in a fair and amicable manner. If it is not
          possible to resolve the disputes ourselves, then you and Trademarkia
          both hereby agree that all disputes or claims between you and
          Trademarkia of any nature whatsoever, including but not limited to
          those relating to use of the Site or the quality or appropriateness of
          the services offered by Trademarkia, shall be resolved by binding
          arbitration before Judicial Arbitration and Mediation Services
          (&quot;JAMS&quot;) and be resolved only in the JAMS offices in the
          County of Santa Clara, California and under California laws.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <span>
            You agree that, by entering into this Agreement, you are waiving the
            right to a trial by jury or to participate in a class action.
          </span>
        </b>
        <span>
          This Agreement evidences a transaction in interstate commerce, and
          thus the Federal Arbitration Act governs the interpretation and
          enforcement of this provision. This arbitration provision shall
          survive the termination of this Agreement.
          <b>
            YOU AND TRADEMARKIA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
            OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING
          </b>
          . Further, unless both you and Trademarkia agree otherwise, the
          arbitrator may not consolidate more than one person's claims, and may
          not otherwise preside over any form of a representative or class
          proceeding.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          The arbitration shall be conducted by the Judicial Arbitration and
          Mediation Services (&quot;JAMS&quot;). Subject to the preceding
          provisions agreed to by the parties contained herein, the arbitrator
          must decide all disputes in accordance with the chosen association’s
          rules and in accordance with California law, and shall have the power
          and obligation to decide all matters submitted, including
          arbitrability and legal questions raised by pleading or summary
          judgment motions. In the event that any dispute or claim is determined
          not subject to arbitration by the arbitrator, all parties agree that
          their respective rights and obligations shall be governed by the laws
          of the State of California, without reference to conflict of laws
          principles that would require the application of the laws of any other
          jurisdiction. Furthermore, in the event that any dispute or claim is
          determined not subject to arbitration by the arbitrator, all parties
          agree that any and all legal action or proceedings shall be instituted
          in a state or federal court in California, and in the Counties of
          Santa Clara.
        </span>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <b>
          <span>
            You understand that it is free to consult with other counsel about
            the wisdom of agreeing to arbitration or to any other term of this
            agreement before accepting it, and you agree that it is voluntarily
            accepting this arbitration agreement.
          </span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 22</span>
          </u>
        </b>
        <b>
          <span>- CLASS ACTION WAIVER</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Any arbitration under these Terms will take place on an individual
          basis; class arbitrations and class/representative/collective actions
          are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN EACH’S INDIVIDUAL CAPACITY, AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/OR
          REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
          GENERAL ACTION AGAINST THE OTHER. Further, unless both parties agree
          otherwise, the arbitrator may not consolidate more than one party’s
          claims, and may not otherwise preside over any form of a
          representative or class proceeding.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 23</span>
          </u>
        </b>
        <b>
          <span>- WAIVER AND SEVERABILITY OF TERMS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          At any time, should Trademarkia fail to exercise or enforce any right
          or provision of this Agreement, such failure shall not constitute a
          waiver of such right or provision. If any provision of this Agreement
          is found by a court of competent jurisdiction to be invalid, the
          parties nevertheless agree that the court should endeavor to give
          effect to the parties’ intentions as reflected in the provision, and
          the other provisions of the Agreement remain in full force and effect.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 24</span>
          </u>
        </b>
        <b>
          <span>- NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          You acknowledge, understand and agree that your account is
          non-transferable and any rights to your ID and/or contents within your
          account shall terminate upon your death. Upon receipt of a copy of a
          death certificate, your account may be terminated and all contents
          therein permanently deleted.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 25</span>
          </u>
        </b>
        <b>
          <span>- STATUTE OF LIMITATIONS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          You acknowledge, understand and agree that regardless of any statute
          or law to the contrary, any claim or action arising out of or relating
          to the use of our Services or the Agreement must be filed within two
          (2) years after said claim or cause of action arose or shall be
          forever barred.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 26</span>
          </u>
        </b>
        <b>
          <span>- VIOLATIONS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Please report any and all violations of this Agreement to our Customer
          Service Agent as follows:
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>Mailing Address:</span>
          </u>
        </b>
      </p>

      <p>
        <span>Trademarkia P.C.</span>
      </p>

      <p>
        <span>440 E. Southern Ave,</span>
      </p>

      <p>
        <span>Tempe AZ 85282</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>Telephone:</span>
          </u>
        </b>
      </p>

      <p>
        <span>{CONTACT_NUMBER.US_CANADA} (Toll Free U.S./Canada)</span>
      </p>

      <p>
        <span>{CONTACT_NUMBER.US} ( U.S. Local/International)</span>
      </p>

      <p>
        <span>{CONTACT_NUMBER.EU} ( Europe)</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>Email:</span>
          </u>
        </b>
      </p>

      <p>
        <span>
          <a>
            <span>customer.service@trademarkia.com</span>
          </a>
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 27</span>
          </u>
        </b>
        <b>
          <span>- GOVERNMENT REQUESTS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          In order to cooperate with governmental requests, subpoenas or court
          orders, to protect our systems, or to ensure the integrity and
          operation of our business and systems, we may access and disclose any
          information we consider necessary or appropriate, including and
          without limitation, your information, IP address, and usage history.
          Our right to disclose any such information is governed by the terms of
          our{" "}
          <a href="/privacy_policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 28</span>
          </u>
        </b>
        <b>
          <span>- FOREIGN ACCESS OF SITE</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          www.incdecentral.com is controlled, operated and administered by
          Trademarkia from our offices within the USA. if you access the Site
          from a location outside the USA, you are responsible for compliance
          with all local laws. You agree that you will not use Trademarkia’s
          content accessed through the Site in any country or in any manner
          prohibited by any applicable laws, restrictions or regulations.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 29</span>
          </u>
        </b>
        <b>
          <span>- ERRORS, INACCURACIES, AND OMISSIONS</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Occasionally there may be information on our Site that contains
          typographical errors, inaccuracies or omissions that may relate to
          product descriptions, pricing, promotions, offers, and product
          charges. We reserve the right to correct any errors, inaccuracies, or
          omissions, and to change or update information or cancel orders if any
          information on the Site or on any related Site is inaccurate at any
          time without prior notice (including after you have submitted your
          order).
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <p>
        <span>
          We undertake no obligation to update, amend or clarify information on
          the Site, or on any related Site, including without limitation,
          pricing information, except as required by law. No specified update or
          refresh date applied on the Site or on any related Site, should be
          taken to indicate that all information on the Site or on any related
          Site has been modified or updated.
        </span>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 30</span>
          </u>
        </b>
        <b>
          <span>- REPRESENTATION</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          Trademarkia does not represent any users unless it has entered into a
          separate retainer agreement with them and after a separate conflict
          check. Mere use of the Site does not mean that you automatically
          become a client of Trademarkia.
        </span>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <b>
          <u>
            <span>SECTION 31</span>
          </u>
        </b>
        <b>
          <span>- ACKNOWLEDGEMENT</span>
        </b>
      </p>

      <p>
        <b>
          <span>&nbsp;</span>
        </b>
      </p>

      <p>
        <span>
          BY USING OUR SERVICES OR ACCESSING OUR WEBSITE, YOU ACKNOWLEDGE THAT
          YOU HAVE READ THESE TERMS AND AGREE TO BE BOUND BY THEM.
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>
    </>
  );
}

/**

 */
