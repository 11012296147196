import { BlueWhiteDropArrow, Man } from "assets";
import { useScroll } from "contexts/scroll";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useRouter } from "next/router";
import { useWorkflow } from "pages/workflow";
import React, { useRef, useEffect, useState, FC } from "react";
import classes from "styles/components/Home/Plans.module.scss";
import { event } from "utils/gtag";
import Button from "../Button";

interface Testimony {
  content: JSX.Element;
  image: FC;
  name: string;
}

interface Card {
  ID?: string;
  name: string;
  theme: string;
  sections: {
    title: string;
    description?: string;
    extender?: boolean;
  }[];
  filing_fee: number;
  state_fee: number;
  isSecondary?: boolean;
  testimony?: Testimony;
}

export const cardsDetails: Card[] = [
  {
    name: "New Business",
    theme: "#6C77A0",
    sections: [
      {
        title: "Online Business formation",
        description: "Prepare & file your company for Incorporation",
      },
      {
        title: "Comprehensive Name Search",
        description: "Researching the availability of the name",
      },
      {
        title: "Electronic Delivery",
        description: "Receive the Articles of Incorporation electronically",
      },
    ],
    filing_fee: NaN,
    state_fee: NaN,
  },
  {
    name: "Ready to Open",
    theme: "#E92169",
    sections: [
      {
        title: "New Business Features",
        description: "All New Business features and the following features.",
        extender: true,
      },
      {
        title: "Registered Agent Service (1-year)",
        description: "Get the legal documents and notices in your hand",
      },
      {
        title: "EIN Registration",
        description: "Get EIN to open bank accounts and hire employees",
      },
    ],
    isSecondary: true,
    filing_fee: NaN,
    state_fee: NaN,
    testimony: {
      content: (
        <p>
          Really easy to use and great communication between myself and the
          lawyer. <span>I would highly recommend this to anyone</span> wanting
          an easy experience in trademarking their IP.
        </p>
      ),
      name: "Jeremy William",
      image: Man,
    },
  },

  {
    name: "Ready to Grow",
    theme: "#6C77A0",
    sections: [
      {
        title: "Ready to Open Features",
        description: "All Ready to Open features and the following features.",
        extender: true,
      },
      {
        title: "Business Documents",
        description:
          "Also get Corporate Bylaws, Organizational minutes, Banking Resolution, Contractor Agreement, Non-Disclosure Agreement",
      },
      {
        title: "Business Licenses",
        description:
          "Forms needed to apply for State Sellers licenses and tax certificates",
      },
    ],
    filing_fee: NaN,
    state_fee: NaN,
    testimony: {
      content: (
        <p>
          Really easy to use and great communication between myself and the
          lawyer. <span>I would highly recommend this to anyone</span> wanting
          an easy experience in trademarking their IP.
        </p>
      ),
      name: "Jeremy William",
      image: Man,
    },
  },
];

interface Props {
  openStateEntityModal: () => void;
}

interface PlansProps extends Props {}

export default function Plans({ openStateEntityModal }: PlansProps) {
  const { push } = useRouter();
  const { createScroller } = useScroll();
  const ref = useRef();
  useEffect(() => {
    createScroller("plans", ref);
  }, [ref]);

  const [cards, setCards] = useState(cardsDetails);
  const { products, values, setValues, states, redirectStep } = useWorkflow();

  useEffect(() => {
    setCards((p) =>
      p.map((card) => {
        const product = products?.find(
          (product) => product.product_name === card.name
        );
        return product
          ? {
              ...card,
              filing_fee:
                values?.entity === "DAO"
                  ? product.dao_pricing
                  : product.product_price || NaN,
              ID: product.ID,
            }
          : card;
      })
    );
  }, [products, values?.entity]);
  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        {cards.map((card) => (
          <div className={classes.cardOuter} key={card.name}>
            <Card
              {...card}
              state_fee={values?.state_fee}
              onClick={() => {
                event({ action: "workflow_step_0_submit" });
                setValues((p) => ({ ...p, product: card.ID }));
                const { entity, plan_state } = values || {};
                if (!entity || !plan_state || !card.ID) {
                  openStateEntityModal();
                  return;
                } else push({ pathname: "/", query: { step: 1 } });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

interface CardProps extends Card {
  onClick: () => void;
}

const Card = ({
  name,
  theme,
  filing_fee,
  state_fee,
  sections,
  isSecondary,
  ID,
  onClick,
}: CardProps) => {
  const { values } = useWorkflow();
  // const dimensions = useWindowDimensions();
  // const height = parseInt(dimensions.height.toString());
  // const [openSection, setOpenSection] = useState(NaN);
  return (
    <div
      className={[
        classes.cardContainer,
        // height < 650 ? classes.short : "",
      ].join(" ")}
    >
      <div
        style={{ borderTop: `10px solid ${theme}` }}
        className={classes.card}
      >
        <div className={classes.top}>
          <h3 style={{ color: theme }}>{name}</h3>
          <div
            style={{ borderTop: `3px solid ${theme}` }}
            className={classes.span}
          />
          <div className={classes.bottom}>
            <div className={classes.fees}>
              <div className={classes.fee}>
                <h4>Filing fee</h4>
                <p style={{ color: theme }}>${filing_fee}</p>
              </div>
              <div className={classes.fee}>
                <h4>State fee</h4>
                <p style={{ color: theme }}>
                  {state_fee ? `$${state_fee}` : "Select state above"}
                </p>
              </div>
            </div>
          </div>
          <Button
            className={classes.select}
            variant={values && values.product === ID ? "filled" : "outlined"}
            theme={isSecondary ? "secondary" : "primary"}
            onClick={onClick}
          >
            {values?.product === ID ? "Continue with this plan" : "Select Plan"}
          </Button>
          <div className={classes.sections}>
            {sections.map(({ title, description, extender }, idx) => (
              <div
                key={title}
                className={[
                  classes.section,
                  // idx === openSection ? "" : classes.collapsed,
                ].join(" ")}
                // onClick={() =>
                //   setOpenSection((p) => (p === idx ? NaN : idx))
                // }
              >
                <div className={classes.sectionHead}>
                  <h4
                  // style={{
                  //   color: extender && height < 650 ? theme : "",
                  // }}
                  >
                    {title}
                  </h4>
                  {/* {height < 650 && (
                    <div
                      className={[
                        classes.arrow,
                        openSection === idx ? classes.open : classes.closed,
                      ].join(" ")}
                    >
                      <BlueWhiteDropArrow />
                    </div>
                  )} */}
                </div>
                <p>{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* {testimony && (
        <div className={classes.testimony}>
          {testimony.content}
          <div className={classes.person}>
            <testimony.image />
            <h3>{testimony.name}</h3>
          </div>
        </div>
      )} */}
    </div>
  );
};
