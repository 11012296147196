import { Agent, Doc, EIN, License } from "assets";
import Form from "components/Form";
import WorkflowButtons from "components/FormInput/WorkflowButtons";
import Genie from "components/Genie";
import ServiceCard from "components/ServiceCard";
import cities from "data/cities";
import useForm from "hooks/useForm";
import useLocalStorage from "hooks/useLocalStorage";
import { FormProp, HandleSubmit, Service } from "interfaces";
import { useRouter } from "next/router";
import { useWorkflow, WorkflowContext } from "pages/workflow";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  postWorkflow4,
  postWorkflow5,
  postWorkflow6,
  postWorkflow7,
  postWorkflowAddons,
} from "services/workflow";
import { event } from "utils/gtag";
import { AnyObjectSchema, number, object, string } from "yup";
import Meta from "components/Meta";

export default function Services() {
  const {
    values,
    setValues,
    changeValue,
    servicesDetails,
    getOrder,
    getHistory,
    getCart,
    products,
    getPendingAddons,
    states,
    setSkipped,
    redirectStep,
    setRedirectStep,
  } = useWorkflow();
  const { push, pathname } = useRouter();

  useEffect(() => {
    event({ action: "workflow_step_4_view" });
  }, []);

  const [current, setCurrent] = useState(0);
  const services: {
    intro: string;
    description: string;
    recommendation?: string;
    rejectionText: string;
    cardProps: Service;
    valuesKey: string;
    requestKey: string;
    addonRequestKey?: string;
    add_on_name: string;
    includedIn?: string[];
    fetcher: (
      ...props: any[]
    ) => Promise<{
      body?: any;
      error?: any;
      msg?: string;
      statusCode?: number;
    }>;
    step: number;
    alternateForm?: FormProp[];
    schema?: AnyObjectSchema;
    handleSubmit?: HandleSubmit;
  }[] = [
    {
      intro: values?.state
        ? `${values.state} is a great state!`
        : "Good Choice!",
      description:
        "Do you plan to open a bank account, credit card, or hire employees too?",
      recommendation: "If so, you will need an EIN. We can do that for you.",
      rejectionText: "No, I'll do it myself",
      includedIn: ["Ready to Grow", "Ready to Open"],
      cardProps: {
        title: "File for EIN",
        image: EIN,
        price: NaN,
        unit: "one time",
        features: [
          "Open a business bank account",
          "Open a business credit card and start building your business' credit",
          "Hire employees",
          "Keep your Social Security Number private",
        ],
      },
      valuesKey: "if_ein_number",
      requestKey: "ein_number",
      add_on_name: "EIN number service",
      fetcher: postWorkflow4,
      step: 4,
    },
    {
      intro: "This one is important.",
      description:
        "Where do want to receive legal notices, such as pending lawsuits and State correspondences about required documents?\nYou must have a person available to receive such notices in the state where you are filing your business.",
      recommendation:
        "Our Registered Agent will receive your documents and forward them to you.",
      rejectionText: "No, I already have one",
      includedIn: ["Ready to Grow", "Ready to Open"],
      cardProps: {
        title: "Assign IncDecentral as my Registered Agent",
        image: Agent,
        price: NaN,
        unit: "year",
        features: [
          "Receive notices of pending lawsuits on your behalf",
          "Receive State correspondences",
          "1-year of service",
        ],
      },
      valuesKey: "if_registered_agent",
      requestKey: "if_registered_agent",
      addonRequestKey: "registered_agent",
      add_on_name: "Registered agent service",
      fetcher: postWorkflow5,
      step: 5,
      alternateForm: [
        [
          {
            name: "agent_name",
            placeholder: "Enter agent name",
            label: "Agent Name",
          },
          {
            name: "agent_address_1",
            placeholder: "Address line 1",
            label: "Street Address 1",
          },
        ],
        [
          {
            name: "agent_address_2",
            placeholder: "Address line 2",
            label: "Street Address 2",
          },
          {
            name: "agent_state",
            placeholder: "Select a State",
            label: "State",
            searchable: true,
            dropUpward: true,
            type: "select",
            options:
              states?.map((state) => ({
                value: state.state_name,
              })) || [],
          },
        ],
        [
          {
            name: "agent_city",
            placeholder: "Select a city",
            label: "City",
            type: "select",
            allowCustom: true,
            disallowNumbers: true,
            options:
              cities[values?.agent_state]?.map((i) => ({ value: i })) || [],
            dropUpward: true,
          },
          {
            name: "agent_zip",
            placeholder: "94040",
            label: "Postal Code",
            type: "number",
          },
        ],
      ],
      schema: object().shape({
        agent_name: string()
          .required()
          .max(30),
        agent_city: string()
          .required()
          .max(100),
        agent_address_1: string()
          .required()
          .max(100),
        agent_address_2: string()
          .nullable()
          .max(100),
        agent_state: string()
          .required()
          .max(100),
        agent_zip: number()
          .required("ZIP is required")
          .min(10000, "ZIP must be atleast 5 digits")
          .max(999999, "ZIP can be atmost 6 digits")
          .typeError("ZIP should be a number"),
      }),
      handleSubmit: async () => {
        const { error } = await postWorkflow5({
          current_step: step,
          [requestKey]: (!!values?.[valuesKey]).toString(),
          name: values.agent_name,
          street_address_1: values.agent_address_1,
          street_address_2: values.agent_address_2,
          city: values.agent_city,
          state: values.agent_state,
          zip: parseInt(values.agent_zip),
        });
        if (error) {
          toast.error("Something went wrong");
          return;
        }
        await Promise.all([
          getOrder(),
          getHistory(),
          getCart(),
          getPendingAddons(),
        ]);
        toNext();
      },
    },
    {
      intro: "We are all a team working on this now!",
      description:
        "You will need corporate and business documents to operate properly. Our attorney-drafted documents will set you right from the start.",
      rejectionText: "No, I'll do it myself",
      includedIn: ["Ready to Grow"],
      cardProps: {
        title: "Corporate Documents Package",
        image: Doc,
        price: NaN,
        unit: "one time",
        features: [
          "Corporate bylaws",
          "Organizational minutes",
          "Banking resolution",
          "Non-disclosure agreement",
          "Contractor agreement",
        ],
      },
      valuesKey: "if_corporate_documents",
      requestKey: "corporate_documents",
      add_on_name: "Corporate documents service",
      fetcher: postWorkflow6,
      step: 6,
    },
    {
      intro: "One last important step!",
      description:
        "I want to make sure you have everything you will need to start your business properly with the additional forms you will need to operate your business.",
      recommendation: "I have just the thing for you.",
      rejectionText: "No, I'll do it myself",
      includedIn: ["Ready to Grow"],
      cardProps: {
        title: "Business License Package",
        image: License,
        price: NaN,
        unit: "one time",
        features: [
          "State Sellers License/permit application",
          "State tax registration form",
        ],
      },
      valuesKey: "if_license_service",
      requestKey: "business_license",
      add_on_name: "Business license service",
      fetcher: postWorkflow7,
      step: 7,
    },
    // {
    //   intro: "Want to get this done in 3 days?",
    //   description:
    //     "Express Filing will ensure your application is filed with the state in a matter of days not weeks.",
    //   recommendation: "Here is your express package to success.",
    //   rejectionText: "No, I'll do it myself",
    //   cardProps: {
    //     title: "Express Filing Service",
    //     image: Filing,
    //     price: NaN,
    //     unit: "month",
    //     features: ["Get your application filed within 3 days."],
    //   },
    //   valuesKey: "if_express_service",
    //   requestKey: "express_service",
    //   add_on_name: "Express filing service",
    //   fetcher: postWorkflow8,
    //   step: 8,
    // },
  ];

  const [formOpen, setFormOpen] = useLocalStorage<{ [key: string]: boolean }>(
    "formOpen",
    {}
  );

  const product = products?.find(({ ID }) => values?.product === ID);
  const filteredServices = services.filter((service) =>
    service.includedIn && product
      ? !service.includedIn.includes(product?.product_name)
      : true
  );

  const {
    intro,
    description,
    recommendation,
    cardProps,
    rejectionText,
    add_on_name,
    requestKey,
    valuesKey,
    fetcher,
    step,
    alternateForm,
    schema,
    handleSubmit,
    addonRequestKey,
  } = filteredServices[current] || {};
  useEffect(() => {
    if (!filteredServices.length) return;
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, [current]);

  useEffect(() => {
    if (!filteredServices.length) return;
    // handleChangeAddons();
    if (values?.[valuesKey]) setFormOpen((p) => ({ ...p, [current]: false }));
  }, [values?.[valuesKey]]);

  const handleChangeAddons = async () => {
    if (!filteredServices.length) return;
    if (alternateForm && !values[valuesKey]) {
      const { error } = await postWorkflowAddons({
        [addonRequestKey]: "false",
      });
      if (error) return { error };
    } else {
      const { error } = await fetcher({
        current_step: step,
        [requestKey]: (!!values?.[valuesKey]).toString(),
      });
      console.log({ step, error });
      if (error) return { error };
    }
    await Promise.all([
      getOrder(),
      getHistory(),
      getCart(),
      getPendingAddons(),
    ]);
  };

  const { formProps } = useForm({
    redirectStep,
    setRedirectStep,
    entries: alternateForm,
    schema,
    onSubmit: handleSubmit,
    values,
  });
  const toNext = async () => {
    if (current > filteredServices.length - 2) {
      event({ action: "workflow_step_4_submit" });
      push({ pathname, query: { step: 5 } });
    } else {
      const res = await handleChangeAddons();
      console.log({ res });
      if (res?.error) toast.error("Something went wrong");
      else setCurrent(current + 1);
    }
  };

  if (!filteredServices.length) {
    push({ pathname, query: { step: 5 } });
    return <></>;
  }

  useEffect(() => {
    if (
      typeof redirectStep !== "number" ||
      isNaN(redirectStep) ||
      4 === redirectStep
    )
      return;
    push({ pathname, query: { step: redirectStep } });
  }, [redirectStep]);

  return (
    <>
      <Meta tags={{ title: "Services" }} />
      <div>
        <Genie
          initialDelay={500}
          prompts={[
            { title: intro, description },
            {
              servicePrompt: recommendation,
              components: [
                () => (
                  <ServiceCard
                    {...cardProps}
                    price={
                      servicesDetails?.find(
                        (s) => s.add_on_name === add_on_name
                      )?.add_on_price
                    }
                    isAdded={!!values?.[valuesKey]}
                    setIsAdded={changeValue(valuesKey)}
                  />
                ),
              ],
            },
          ]}
          actionText={rejectionText}
          checked={alternateForm ? !!formOpen[current] : undefined}
          action={async () => {
            setSkipped((p) => [
              ...p.filter((i) => i !== add_on_name),
              add_on_name,
            ]);
            setValues((p) => ({ ...p, [valuesKey]: false }));
            if (alternateForm) {
              setFormOpen((p) => ({ ...p, [current]: !p[current] }));
            } else {
              const { error } = await fetcher({
                current_step: step,
                [requestKey]: (!!values?.[valuesKey]).toString(),
              });
              console.log({ error });
              if (error) {
                toast.error("Something went wrong");
                return;
              }
              toNext();
            }
          }}
          reset={current}
        />
        {alternateForm && formOpen[current] && (
          <Form {...formProps} context={WorkflowContext}>
            <WorkflowButtons
              prev={current < 1 ? 3 : () => setCurrent(current - 1)}
            />
          </Form>
        )}
        {!(alternateForm && formOpen[current]) && (
          <WorkflowButtons
            next={async () => {
              if (!values?.[valuesKey])
                setSkipped((p) => [
                  ...p.filter((i) => i !== add_on_name),
                  add_on_name,
                ]);
              if (!alternateForm || values[valuesKey]) toNext();
              else {
                const error =
                  "Please select our service or provide your agent's details";
                toast.error(error);
                throw error;
              }
            }}
            prev={
              current < 1
                ? ["Ready to Grow"].includes(product?.product_name)
                  ? 2
                  : 3
                : () => setCurrent(current - 1)
            }
          />
        )}
      </div>
    </>
  );
}
