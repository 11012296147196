import { LogoRound } from "assets";
import React, { useEffect, useState } from "react";
import classes from "styles/components/Loader.module.scss";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  messages?: string[];
  hide?: boolean;
  dark?: boolean;
}

function Loader({ messages, hide, dark, className, ...props }: Props) {
  const [shown, setShown] = useState(0);
  const [almost, setAlmost] = useState(false);

  useEffect(() => {
    if (!messages) return;
    setTimeout(
      () => setShown((p) => (p < (messages.length || 0) - 1 ? p + 1 : p)),
      2000
    );
  }, [shown]);

  useEffect(() => {
    if (almost) return;
    setTimeout(() => setAlmost(true), 5000);
  }, [almost]);

  if (hide) return <></>;

  return (
    <div>
      <div {...props} className={[className, classes.container].join(" ")}>
        <div
          className={[classes.loader, dark ? classes.dark : classes.light].join(
            " "
          )}
        >
          <LogoRound />
          <div className={classes.text}>
            <p className={classes.loading}>
              {almost ? "Almost done!" : "Loading..."}
            </p>
            {messages?.length && (
              <p className={classes.message}>{messages[shown]}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
