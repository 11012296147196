import { ROUTES } from "config/api";
import { Order, Paginated } from "interfaces";
import { createFetcher } from "./helpers";

export const fetchPaymentStatus = createFetcher.post<{
  status_complete: boolean;
  status_intent: boolean;
  stripe: string;
}>(ROUTES.ORDERS.CHECK, {
  addSessionCreds: true,
});
export const fetchOrderList = createFetcher.post<{
  completed?: Order[];
  incomplete?: Order[];
  if_next: boolean;
  page: number;
}>(ROUTES.ORDERS.LIST, { addSessionCreds: true });
export const fetchCompleteOrders = createFetcher.post<Paginated<Order>>(
  ROUTES.ORDERS.COMPLETE,
  { addSessionCreds: true }
);
export const fectchIncompleteOrders = createFetcher.post<Paginated<Order>>(
  ROUTES.ORDERS.INCOMPLETE,
  { addSessionCreds: true }
);
