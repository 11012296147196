import Form from "components/Form";
import WorkflowButtons from "components/FormInput/WorkflowButtons";
import Genie from "components/Genie";
import cities from "data/cities";
import useForm from "hooks/useForm";
import { FormProp, HandleSubmit } from "interfaces";
import { useRouter } from "next/router";
import { useWorkflow, WorkflowContext } from "pages/workflow";
import React, { useEffect, useState } from "react";
import { postWorkflow3, postWorkflowAddons } from "services/workflow";
import { event } from "utils/gtag";
import { number, object, string } from "yup";
import Meta from "components/Meta";

const schema = object().shape({
  street_address_1: string()
    .required()
    .max(100, "Address 2 cannot be longer than 100 characters"),
  street_address_2: string()
    .nullable()
    .max(100, "Address 2 cannot be longer than 100 characters"),
  city: string()
    .required()
    .max(100),
  state: string()
    .required()
    .max(100),
  zip: number()
    .required("ZIP is required")
    .min(10000, "ZIP must be atleast 5 digits")
    .max(999999, "ZIP can be atmost 6 digits")
    .typeError("ZIP should be a number"),
});

export default function BusinessAddress() {
  const { push, pathname } = useRouter();
  const [showForm, setShowForm] = useState(true);
  const {
    values,
    setValues,
    states,
    redirectStep,
    setRedirectStep,
  } = useWorkflow();

  useEffect(() => {
    event({ action: "workflow_step_3_view" });
  }, []);

  const entries: FormProp[] = [
    [
      { name: "street_address_1", label: "Street Address 1" },
      {
        name: "street_address_2",
        label: "Street Address 2"
      },
    ],
    [
      {
        name: "state",
        label: "State",
        type: "select",
        searchable: true,
        options:
          states?.map((state) => ({
            value: state.state_name,
          })) || [],
      },
      {
        name: "city",
        label: "City",
        placeholder: "Enter City",
        type: "select",
        allowCustom: true,
        disallowNumbers: true,
        options: cities[values?.state]?.map((i) => ({ value: i })) || [],
      },
    ],
    [
      { name: "zip", label: "ZIP/ Postal Codes", type: "number" },
      () => <div />,
    ],
  ];

  useEffect(() => {
    if (values?.plan_state)
      setValues((p) => ({
        ...p,
        state: p.state || values?.plan_state.split("+++")[0],
        agent_state: p.agent_state || values?.plan_state.split("+++")[0],
      }));
  }, [values?.plan_state]);

  const handleSubmit: HandleSubmit = async (redirect) => {
    event({ action: "workflow_step_3_submit" });
    const body = {
      current_step: 3,
      street_address_1: values.street_address_1,
      street_address_2: values.street_address_2,
      city: values.city,
      state: values.state,
      zip: parseInt(values.zip),
      if_professional_address: "false",
    };
    await postWorkflow3(body);
    push({
      pathname,
      query: { step: typeof redirect === "number" ? redirect : 4 },
    });
  };

  const handleAcceptService = async () => {
    if (!values?.if_professional_address)
      throw "Please enter an address or select a service";
    const body = {
      current_step: 3,
      if_professional_address: "true",
    };
    await postWorkflow3(body);
    push({ pathname, query: { step: 4 } });
  };

  const addService = async () => {
    await postWorkflowAddons({
      business_address: (!!values?.if_professional_address).toString(),
    });
  };

  useEffect(() => {
    if (values?.if_professional_address) setShowForm(false);
    addService();
  }, [values?.if_professional_address]);

  const { formProps } = useForm({
    redirectStep,
    setRedirectStep,
    schema: values?.if_professional_address ? undefined : schema,
    entries,
    onSubmit: handleSubmit,
    values,
  });

  return (
    <>
      <Meta tags={{ title: "Business Address" }} />
      <div>
        <Genie
          prompts={[
            {
              title: "That is an amazing idea!",
              description: `The ${values?.category} sector will be thrilled to have you!`,
            },
            // {
            //   title: showForm
            //     ? undefined
            //     : "Most states require an in-state address. What address do you want to list as your in-state business address?",
            //   description: showForm
            //     ? "That is alright, you will be missing out on this 😔"
            //     : "We have a recommended service to help you out.",
            //   components: [
            //     () => (
            //       <ServiceCard
            //         image={Building}
            //         price={49}
            //         title="Professional Business Address service"
            //         unit="month"
            //         features={["Get Virtual mail service worth $49 for Free"]}
            //         isAdded={values?.if_professional_address}
            //         setIsAdded={changeValue("if_professional_address")}
            //       />
            //     ),
            //   ],
            // },
            ...(showForm
              ? [
                  {
                    description:
                      "Most states require an in-state address. What address do you want to list as your in-state business address",
                  },
                ]
              : []),
          ]}
          actionText={showForm ? undefined : "No I'll add my own Address"}
          action={() => {
            setShowForm(true);
            setValues((p) => ({ ...p, if_professional_address: false }));
          }}
        />
        {showForm && (
          <Form context={WorkflowContext} {...formProps}>
            <WorkflowButtons prev={2} />
          </Form>
        )}
        {!showForm && <WorkflowButtons prev={2} next={handleAcceptService} />}
      </div>
    </>
  );
}
