import {
  Checked,
  Close,
  GreenCheckRound,
  Info,
  PlusPink,
  PlusWhite,
} from "assets";
import { toggle, useGlobals } from "contexts/globals";
import { useUser } from "contexts/user";
import { Order } from "interfaces";
import { useRouter } from "next/router";
import { useWorkflow } from "pages/workflow";
import React, { useEffect, useMemo, useState } from "react";
import {
  fetchOrder,
  postCoupon,
  postWorkflowAddons,
  removeCoupon,
} from "services/workflow";
import classes from "styles/components/Workflow/OrderDetailsCard.module.scss";
import Button from "../Button";

export const services = [
  {
    valuesKey: "if_ein_number",
    requestKey: "ein_number",
    add_on_name: "EIN number service",
    includedIn: ["Ready to Grow", "Ready to Open"],
    tip:
      "EIN is a unique nine-digit number required by your business for tax purposes, opening bank accounts, and applying for credit in the future.",
  },
  {
    valuesKey: "if_registered_agent",
    requestKey: "registered_agent",
    add_on_name: "Registered agent service",
    includedIn: ["Ready to Grow", "Ready to Open"],
    tip:
      "The registered agent will provide a legal address within the jurisdiction where legal documents may be served during business hours.",
  },
  {
    valuesKey: "if_corporate_documents",
    requestKey: "corporate_documents",
    add_on_name: "Corporate documents service",
    includedIn: ["Ready to Grow"],
    tip:
      "We deliver concierge-level service to guide clients through the most complex business filings and document retrieval anywhere.",
  },
  {
    valuesKey: "if_license_service",
    requestKey: "business_license",
    add_on_name: "Business license service",
    includedIn: ["Ready to Grow"],
    tip:
      "We help your company build and maintain requirements for state business licenses, permit registration, tax registration, and much more.",
  },
  {
    valuesKey: "if_express_service",
    requestKey: "express_service",
    add_on_name: "Express filing service",
    tip:
      "We provide the opportunity to submit for filing a business entity document within a guaranteed time frame.",
  },
  {
    valuesKey: "if_professional_address",
    requestKey: "business_address",
    add_on_name: "Business address service",
    // tip: "Placeholder tooltip",
  },
];

const ignoredServices = [
  "Registered agent service",
  "Business address service",
];

const serviceMap: { [key: string]: typeof services[number] } = services.reduce(
  (prev, curr) => ({ ...prev, [curr.add_on_name]: curr }),
  {}
);

export default function OrderDetailsCard() {
  const { cartOpen, setCartOpen } = useGlobals();
  const {
    products,
    values,
    total,
    cart,
    pending,
    getCart,
    getOrder,
    getHistory,
    getPendingAddons,
    skipped,
    order,
    setSkipped,
    success,
    successOrder,
  } = useWorkflow();
  const [couponError, setCouponError] = useState("");
  const { sessionsChange } = useUser();

  useEffect(() => {
    if (successOrder?.ID && sessionsChange)
      getCart({ order_id: successOrder.ID });
  }, [successOrder?.ID, sessionsChange]);

  useEffect(() => {
    getPendingAddons();
  }, []);

  const {
    query: { step: strepStr },
  } = useRouter();

  const step = strepStr ? JSON.parse(strepStr.toString()) : 0;
  const plan = products?.find(({ ID }) => ID === values?.product);
  const product = products?.find(({ ID }) => values?.product === ID);

  const showPrice = (price: number) => (price ? `$${price}` : `FREE`);

  const handleAddOn = async (add_on_name: string, bool: boolean) => {
    await postWorkflowAddons({
      [services.find((s) => s.add_on_name === add_on_name)
        .requestKey]: JSON.stringify(bool),
    });
    getPendingAddons();
    getCart();
    getHistory();
    getOrder();
    if (!bool)
      setSkipped((p) => [...p.filter((i) => i !== add_on_name), add_on_name]);
  };
  const filteredPending = useMemo(
    () =>
      pending?.filter(
        (p) =>
          !ignoredServices.includes(p.add_on_name) &&
          skipped.includes(p.add_on_name)
      ),
    [pending, skipped]
  );
  const [coupon, setCoupon] = useState(order?.coupon_code || "");
  const [thisOrder, setThisOrder] = useState<Order | null>(null);

  const {
    query: { order_id },
  } = useRouter();

  useEffect(() => {
    if (!order_id) return;
    fetchOrder({ order_id }).then((res) => setThisOrder(res.body || null));
  }, [order_id]);

  const remCoupon = async () => {
    await removeCoupon({ coupon_code: order.coupon_code });
    getPendingAddons();
    getCart();
    getHistory();
    getOrder();
  };

  const addCoupon = async () => {
    if (!coupon) return setCouponError("Please enter coupon code");
    if (order?.coupon_code)
      await removeCoupon({ coupon_code: order.coupon_code });
    const { error } = await postCoupon({ coupon_code: coupon });
    if (error)
      setCouponError("Invalid coupon code. Please enter a correct one.");
    else setCouponError("");
    getPendingAddons();
    getCart();
    getHistory();
    getOrder();
  };
  if (!cart || !(success ? successOrder : order)) return <></>;
  return (
    <>
      <div
        className={[
          classes.backdrop,
          cartOpen ? classes.active : classes.inactive,
        ].join(" ")}
        onClick={() => toggle(setCartOpen)}
      />
      <div
        className={[
          classes.collapsible,
          cartOpen ? classes.open : classes.closed,
        ].join(" ")}
      >
        <div
          className={[
            classes.container,
            // cartOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          <div className={[classes.section, classes.head].join(" ")}>
            <div className={classes.close}>
              <div
                onClick={() => toggle(setCartOpen)}
                className={classes.circle}
              >
                <Close />
              </div>
            </div>
            <h2>Your order</h2>
            {(success ? successOrder?.company_name : values?.business_name) && (
              <div className={classes.details}>
                <p>
                  {success ? successOrder?.company_name : values?.business_name}{" "}
                  {success ? successOrder?.designator : values?.designator}
                </p>
                <p>
                  {success
                    ? successOrder?.state_filing_fee[0]?.state_name
                    : values?.plan_state?.split("+++")?.[0]}
                </p>
              </div>
            )}
          </div>
          <div className={classes.section}>
            <h4>In your cart </h4>
            <div className={classes.flex}>
              <p>
                {success
                  ? successOrder?.products?.[0]?.product_name
                  : plan?.product_name}
              </p>
              <p>${cart?.BasePrice.Order}</p>
            </div>
            <div className={classes.flex}>
              <p>State Fee</p>
              <p>{showPrice(cart?.BasePrice.State)}</p>
            </div>
          </div>
          {!!cart?.AddOns?.length && (
            <div className={classes.section}>
              <h4>Your Services</h4>
              {cart?.AddOns.map(({ add_on_name, add_on_price }) => {
                const { includedIn } = services.find(
                  (s) => s.add_on_name === add_on_name
                );
                return (
                  <div key={add_on_name} className={classes.flex}>
                    <div className={classes.addon}>
                      {!success && (
                        <span
                          className={
                            !includedIn?.includes(
                              success
                                ? successOrder?.products?.[0]?.product_name
                                : product?.product_name
                            ) && add_on_name !== "Registered agent service"
                              ? classes.clickable
                              : classes.unclickable
                          }
                          onClick={
                            !includedIn?.includes(
                              success
                                ? successOrder?.products?.[0]?.product_name
                                : product?.product_name
                            ) && add_on_name !== "Registered agent service"
                              ? () => handleAddOn(add_on_name, false)
                              : undefined
                          }
                        >
                          {/* {!includedIn?.includes(success
                            ? successOrder?.products?.[0]?.product_name
                            : product?.product_name) && ( */}
                          <Checked />
                        </span>
                      )}
                      <p>{add_on_name}</p>
                    </div>
                    <p>
                      {includedIn?.includes(
                        success
                          ? successOrder?.products?.[0]?.product_name
                          : product?.product_name
                      )
                        ? "INCLUDED"
                        : showPrice(add_on_price)}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          <div className={classes.section}>
            <div className={classes.flex}>
              <h3>Total Price</h3>
              <div>
                <h3 className={classes.price}>
                  $
                  {success
                    ? thisOrder?.coupon_code
                      ? thisOrder?.price_after_coupon
                      : thisOrder?.price
                    : total}
                  {(success
                    ? thisOrder?.coupon_code &&
                      thisOrder?.price_after_coupon < thisOrder?.price
                    : order?.coupon_code && total < order?.price) && (
                    <span className={classes.strike}>
                      ${success ? thisOrder?.price : order?.price}
                    </span>
                  )}
                </h3>
              </div>
            </div>
            {(order?.coupon_code || (step > 4 && !success)) &&
              !(!order?.coupon_code && success) && (
                <div
                  className={[
                    // classes.section,
                    classes.coupon,
                    Boolean(order?.coupon_code)
                      ? classes.applied
                      : classes.notApplied,
                  ].join(" ")}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (order?.coupon_code) remCoupon();
                      else addCoupon();
                    }}
                    className={classes.inputContainer}
                  >
                    <input
                      value={order?.coupon_code ? order?.coupon_code : coupon}
                      onChange={
                        order?.coupon_code
                          ? undefined
                          : (e) => setCoupon(e.target.value.toUpperCase())
                      }
                      type="text"
                      placeholder="Enter coupon code"
                    />
                    {!success && (
                      <button type="submit">
                        {order?.coupon_code ? "Remove" : "Enter"}
                      </button>
                    )}
                  </form>
                  {coupon && order?.coupon_code === coupon ? (
                    <div className={classes.appliedCheck}>
                      <GreenCheckRound />
                      <p>Coupon applied</p>
                    </div>
                  ) : (
                    <div className={classes.couponError}>
                      <p>{couponError}</p>
                    </div>
                  )}
                </div>
              )}
          </div>
          {!!filteredPending?.length && !success && (
            <div className={classes.section}>
              <h4>You are missing out on</h4>
              {filteredPending.map(({ add_on_name }) => (
                <div className={[classes.flex, classes.missing].join(" ")}>
                  <div className={classes.missingText}>
                    <p>{add_on_name}</p>
                    {serviceMap[add_on_name].tip && (
                      <div className={classes.info}>
                        <Info />
                        <div className={classes.tooltip}>
                          <div className={classes.main}>
                            <Info />
                            <div>
                              <h4>{add_on_name}</h4>
                              <h6>{serviceMap[add_on_name].tip}</h6>
                            </div>
                          </div>
                          <div className={classes.tri}></div>
                        </div>
                      </div>
                    )}
                  </div>
                  <Button
                    onClick={() => handleAddOn(add_on_name, true)}
                    variant="outlined"
                    size="s"
                    theme="secondary"
                    icon={PlusPink}
                    hoverIcon={PlusWhite}
                  >
                    <p>Add</p>
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* {step > 4 && !success && (
          <div className={[classes.container, classes.coupon].join(" ")}>
            <div className={classes.section}>
              <h2>Have a coupon?</h2>
              <input
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                type="text"
              />
              {coupon && order?.coupon_code === coupon ? (
                <div className={classes.applied}>
                  <GreenCheckRound />
                  <p>Coupon applied</p>
                </div>
              ) : (
                <Button onClick={addCoupon} theme="secondary">
                  Apply
                </Button>
              )}
            </div>
          </div>
        )} */}
      </div>
    </>
  );
}
