import Form from "components/Form";
import WorkflowButtons from "components/FormInput/WorkflowButtons";
import Genie from "components/Genie";
import { useUser } from "contexts/user";
import useForm from "hooks/useForm";
import { anyObject, FormProp, HandleSubmit, Prompt } from "interfaces";
import { useRouter } from "next/router";
import { allowedEntities, useWorkflow, WorkflowContext } from "pages/workflow";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchProfile } from "services/users";
import { postWorkflow1, postWorkflowRecurring } from "services/workflow";
import { event } from "utils/gtag";
import * as yup from "yup";
import Meta from "components/Meta";

const prompts: Prompt[] = [
  {
    title: "👋 Hi! I'm the IncD Genie",
    description:
      "I'll guide you through the filing process. But first, I need to get to know a little bit about you.",
  },
];

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .max(100),
  lastName: yup
    .string()
    .required()
    .max(100),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup
    .string()
    .required()
    .matches(/^\d{3}-\d{3}-\d{4}$/, "Invalid Phone Number"),
});

export default function YourInformation() {
  const { push, pathname, query } = useRouter();
  const { sessionsId, setSessionsId, setSessionsToken } = useUser();
  const {
    setOrderId,
    setValues,
    values,
    redirectStep,
    setRedirectStep,
    state,
    entity,
    plan,
    states,
  } = useWorkflow();

  useEffect(() => {
    if (!values || !states?.length) return;
    const thisState = states?.find((s) => s.state_abbreviation === state);
    if (
      !(thisState
        ? thisState.state_name + "+++" + thisState.ID
        : values?.plan_state) ||
      !(allowedEntities.includes(entity?.toString())
        ? entity
        : entity === "DAO" &&
          (values?.plan_state?.split("+++")?.[0] === "Wyoming" || state == "WY")
        ? "DAO"
        : values?.entity) ||
      !((plan > 0 && plan < 4) || values?.product)
    )
      push(pathname);
  }, [values?.plan_state, values?.entity, values?.product, states]);

  const { user } = useUser();
  const [userChanged, setUserChanged] = useState(0);

  useEffect(() => {
    event({ action: "workflow_step_1_view" });
  }, []);

  useEffect(() => {
    setUserChanged((p) => p + 1);
  }, [sessionsId]);

  useEffect(() => {
    if (values?.email)
      setValues((p) => ({ ...p, email: p.email.toLowerCase() }));
  }, [values?.email]);

  const { profile } = useUser();
  const [firstName, ...restName] = (profile?.full_name || "").split(" ");
  const lastName = restName.join(" ");

  const entries: FormProp[] = [
    [
      {
        name: "firstName",
        label: "First Name",
        placeholder: "Jonathan",
        forceValue: user ? firstName : undefined,
        disallowNumbers: true,
      },
      {
        name: "lastName",
        label: "Last Name",
        placeholder: "Doe",
        forceValue: user ? lastName : undefined,
        disallowNumbers: true,
      },
    ],
    [
      {
        name: "email",
        label: "Email Address",
        placeholder: "john.doe@example.com",
        type: "email",
        forceValue: user?.email,
      },
      {
        name: "phone",
        label: "Phone Number",
        placeholder: "2550123",
        type: "phone",
      },
    ],
  ];

  useEffect(() => {
    if (user?.email) setValues((p) => ({ ...p, email: user?.email }));
  }, [user?.email]);

  const forceLogin = () =>
    push({
      pathname: "/login",
      query: {
        redirect: `"${pathname}?${Object.entries(query)
          .map(([key, value]) => `${key}=${value.toString()}`)
          .join("&")}"`,
        email: values?.email,
      },
    });

  const handleSubmit: HandleSubmit = async (data) => {
    event({ action: "workflow_step_1_submit" });
    const { firstName, lastName, email, phone } = values;
    const [state, state_id] = values?.plan_state.split("+++");
    const body: anyObject = {
      full_name: firstName + " " + lastName,
      email: user?.email || email,
      phone_number:
        values?.phone_code?.replace(/-/g, "") + "" + phone.replace(/-/g, ""),
      product_id: values?.product,
      state,
      state_id,
      entity: values?.entity,
      if_dao: values?.entity === "DAO" ? "true" : "false",
    };

    const si = localStorage.getItem("sessions_id");
    const st = localStorage.getItem("sessions_token");

    const recur = () => {
      try {
        return !!(si && st && JSON.parse(si) && JSON.parse(st));
      } catch (error) {
        return false;
      }
    };

    const emailChanged = async () => {
      try {
        const { body } = await fetchProfile();
        return body.email !== values?.email;
      } catch (error) {
        return true;
      }
    };

    if (userChanged > 1) body.order_id = null;
    if (recur() && !(await emailChanged())) {
      const data = await postWorkflowRecurring(body);
      if (data.error) {
        const status = data?.error?.response?.status;
        switch (status) {
          case 409:
            toast.info("Email already exists. Please login to continue");
            forceLogin();
            break;
          default:
            toast.error("Something went wrong");
        }
        return;
      }
      setOrderId(data.body.ID);
    } else {
      const data = await postWorkflow1({ ...body, create_new_order: "true" });
      if (data.error) {
        const status = data?.error?.response?.status;
        switch (status) {
          case 409:
            toast.info("Email already exists. Please login to continue");
            forceLogin();
            break;
          default:
            toast.error("Something went wrong");
        }
        return;
      }
      // return {
      //   error:
      //     data.error.response?.status === 500
      //       ? "Email already exists"
      //       : data.error.response?.message,
      // };
      setSessionsId(data.body.sessions_id);
      setSessionsToken(data.body.sessions_token);
      setOrderId(data.body.order[0].ID);
    }
    push({ pathname, query: { step: typeof data === "number" ? data : 2 } });
  };

  const { formProps } = useForm({
    redirectStep,
    setRedirectStep,
    schema,
    entries,
    onSubmit: handleSubmit,
    values,
  });

  return (
    <>
      <Meta tags={{ title: "Owner Information" }} />
      <div>
        <Genie prompts={prompts} />
        <Form context={WorkflowContext} {...formProps}>
          <WorkflowButtons prev={0} />
        </Form>
      </div>
    </>
  );
}
