import { BreadArrow, BreadArrowFaded, GreenCheckRound, Warn } from "assets";
import Section from "components/Section";
import { Step } from "interfaces";
import { useWorkflow } from "pages/workflow";
import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "styles/components/Workflow/Breadcrumbs.module.scss";

interface Props {
  steps: Step[];
  current: number;
  onClick?: (step: number) => Promise<void>;
  maxPage: number;
}

export default function Breadcrumbs({
  steps,
  current,
  onClick,
  maxPage,
}: Props) {
  const ref = useRef();
  const innerRef = useRef();
  const parent = ref.current as any;
  const children = (innerRef?.current as any)?.children as any;
  const [mp, setMp] = useState(0);
  const scrollToTab = (idx: number) =>
    parent?.scrollTo(children?.[idx * 2]?.offsetLeft - 80, 0);
  const { setRedirectStep } = useWorkflow();

  useEffect(() => {
    setMp(maxPage);
  }, [maxPage]);

  useEffect(() => {
    scrollToTab(current);
  }, [current]);

  return (
    <Section
      thisRef={ref}
      innerRef={innerRef}
      className={classes.container}
      innerClassName={classes.innerContainer}
    >
      {steps.map(({ label }, i) => (
        <Fragment key={i}>
          <div
            key={label}
            id={`crumb${current}`}
            className={[
              classes.crumb,
              classes[
                i === current
                  ? "present"
                  : i < current || i < mp
                  ? "past"
                  : "future"
              ],
              mp === i && i > current ? classes.mampxmpPage : "",
              i + "-" + mp,
              current < i + 1 ? "" : classes.clickable,
              mp,
            ].join(" ")}
            onClick={async () => setRedirectStep(i)}
          >
            <p>{label}</p>
            <div className={classes.check}>
              <GreenCheckRound />
            </div>
            <div className={classes.warn}>
              <Warn />
            </div>
          </div>
          {i + 1 < steps.length &&
            (i + 1 < current ? <BreadArrow /> : <BreadArrowFaded />)}
        </Fragment>
      ))}
    </Section>
  );
}
